<template>
    <div class="list-container">
        <div class="list-wrapper" :style="{ height: wrapperHeight + 'px' }">
            <!-- header -->
            <v-card class="bg-card-even rounded-0 d-flex align-center" :height="rowHeight" >
                <v-row class="list-header ma-0" no-gutters>
                    <v-col v-for="(col, colIndex) in headers" :key="col.value + colIndex"
                        :cols="col.cols ? col.cols : false"
                        class="d-flex justify-center text-bold list-header-column align-end white--text"
                        :style="{fontSize: '0.8rem'}">{{
                col.text }}
                    </v-col>
                </v-row>
            </v-card>
            <!-- rows -->
            <div v-if="desserts.length == 0" class="d-flex justify-center align-center flex-column mt-16">
                <v-spacer></v-spacer>
                <v-img :src="require('@/assets/images/icons/nodata.png')" height="128px" contain></v-img>
                <div class="text-h5">没有找到数据</div>
                <v-spacer></v-spacer>
            </div>
            <v-card v-else v-for="(item, itemIndex) in currentPageList" :key="item.rowid" class="list-body-card"
                :class="itemIndex % 2 == 0 ? 'bg-card-odd': 'bg-card-even'" :height="rowHeight + 'px'">
                <v-row class="ma-0 h-full">
                    <v-col v-for="col in headers" :key="item.rowid + col.value" :cols="col.cols ? col.cols : false"
                        class="
              d-flex
              justify-center
              align-center
              text-h5
              list-body-column
              pa-0
              h-full
              white--text
            " :class="[
            col.leftDivider ? 'list-body-left-divider' : '',
        ]">
                        <div v-if="col.value == 'berthName'" class="text-lg-h5 list-body-content">
                            <div class="text-h6 text-ali">
                                {{ item[col.value] }}
                            </div>
                        </div>
                        <v-tooltip v-else top :disabled="!isShowTip" color="primary" :max-width="tooltipWidth">
                            <template v-slot:activator="{ on, attrs }">
                                <div v-bind="attrs" v-on="on" class="list-body-content u-line-2 text-body-1"
                                    @mouseover="overflowCheck($event)">
                                    {{ item[col.value] }}
                                </div>
                            </template>
                            <!-- <div class="text-body-1 text-ali">
                                {{ item[col.value] }}
                            </div> -->
                        </v-tooltip>
                    </v-col>
                </v-row>
            </v-card>
        </div>
        <v-pagination class="pagination mt-2" v-model="pageNumber" :length="pageTotal" :total-visible="7"
            @input="onPageChange"></v-pagination>
    </div>
</template>

<script>
export default {
    data() {
        return {
            pageSize: 5,
            pageTotal: 1,
            pageNumber: 1,  //第几页
            rowHeight: 30,
            tooltipWidth: '200px',
            isShowTip: false,
            headers: [
                {
                    text: '泊位',
                    align: 'start',
                    value: 'berthName',
                },
                { text: '靠泊等级(t)', value: 'berthingGrade' },
                { text: '长度(m)', value: 'length' },
                { text: '干舷(m)', value: 'poleSideHeight' },
                { text: '前沿水深(m)', value: 'depth' },
            ],
            desserts: [],
            currentPageList: []
        }
    },
    computed: {
        wrapperHeight() {
            return (this.rowHeight) * this.pageSize + 40;
        }
    },
    mounted() {
        this.desserts = Array.from({ length: 8 }, (_, index) => {
            let leftDivider = index == 0 ? true : false
            return {
                rowid: index,
                berthName: index,
                berthingGrade: '1000t',
                length: '60m',
                poleSideHeight: '1.0m',
                depth: '1.0m',
                fill: true,
                color: 'black',
                leftDivider
            }

        })
        this.refresh()
    },
    methods: {
        refresh() {
            if (this.desserts.length && this.pageSize > 0) {
                this.pageTotal =
                    Math.floor((this.desserts.length - 1) / Number(this.pageSize)) + 1;
                this.currentPageList = this.desserts.filter((item, index) => {
                    if (
                        index >= (this.pageNumber - 1) * this.pageSize &&
                        index <= this.pageNumber * this.pageSize - 1
                    ) {
                        return true;
                    } else {
                        return false;
                    }
                });
            } else {
                this.pageTotal = 1;
                this.currentPageList = [];
            }
            console.log("currentPageList:", this.currentPageList);
            console.log("columns:", this.columns);
        },
        onPageChange(number) {
            this.refresh();
        },
        overflowCheck(event) {
            const target = event.target;
            if (target.scrollHeight > target.clientHeight) {
                this.isShowTip = true;
                // overflow
                console.log("overflow:", event, name);
            } else {
                this.isShowTip = false;
            }
            this.$forceUpdate();
        },
    }
}
</script>

<style lang="scss" scoped>
.list-container {
    position: relative;

    .list-wrapper {
        .list-body-card {
            border-radius: 0px;

            .list-body-column {
                border-radius: 0px;
                position: relative;
                font-family: "alibaba" !important;

                .list-body-content {
                    padding-left: 5px;
                    padding-right: 5px;
                    text-align: center;
                    font-family: "alibaba" !important;
                }
            }

            .list-body-left-divider:before {
                content: "";
                position: absolute;
                left: 0px;
                width: 0px;
                height: 32px;
                opacity: 1;
                border: 1px solid #afb8c1;
            }
        }
    }

    .pagination ::v-deep() {
        .v-pagination__item {
            font-family: "alibaba" !important;
        }
    }
}

.bg-card-odd {
    background-color: #B5BBBE;
}

.bg-card-even {
    background-color: #5C646B;

}
</style>