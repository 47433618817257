<template>
    <div class="h-full d-flex flex-column">
        <div class="d-flex px-2 pt-2 pb-1 align-center">
            <YTag title="隐患整改" />
        </div>
        <div class="d-flex mt-1" style="flex:1">
            <div id="map1" ref="mapContainer1" :style="mapStyle"></div>
            <v-divider vertical inset></v-divider>
            <div id="map2" ref="mapContainer2" :style="mapStyle"></div>
        </div>
    </div>
</template>

<script>
import YTag from '../YTag/YTag'
export default {
    components: { YTag },
    props: {

    },
    data() {
        return {
            chart1: null,
            chart2: null,
            chart1Option: {
                title: {
                    text: '整改中隐患',
                    left: 'center',
                    top: '3%',
                    textStyle: {
                        fontSize: 14
                    }
                },
                tooltip: {
                    trigger: 'item'
                },
                legend: {
                    bottom: '3%',
                    left: 'center',
                    show: true
                },
                series: [
                    {
                        name: '整改中隐患',
                        type: 'pie',
                        radius: ['35%', '50%'],
                        showEmptyCircle: true,
                        emptyCircleStyle: {
                            color: '#00E269'
                        },
                        // avoidLabelOverlap: false,
                        label: {
                            show: false,
                            position: 'center'
                        },
                        labelLine: {
                            show: false
                        },
                        data: [
                            { value: 10, name: '重大', itemStyle: { color: '#C74A6D', borderWidth: 1, borderColor: '#000' } },
                            { value: 73, name: '一般', itemStyle: { color: '#448EF7', borderWidth: 1, borderColor: '#000' } }
                        ]
                    }
                ]
            },
            chart2Option: {
                title: {
                    text: '超期未整改隐患',
                    left: 'center',
                    top: '3%',
                    textStyle: {
                        fontSize: 14
                    }
                },
                tooltip: {
                    trigger: 'item'
                },
                legend: {
                    bottom: '5%',
                    left: 'center',
                    show: true
                },
                series: [
                    {
                        name: '超期未整改隐患',
                        type: 'pie',
                        radius: '50%',
                        showEmptyCircle: true,
                        emptyCircleStyle: {
                            color: '#00E269'
                        },
                        // avoidLabelOverlap: false,
                        label: {
                            show: false,
                            position: 'center'
                        },
                        labelLine: {
                            show: false
                        },
                        data: [
                            { value: 0, name: '重大', itemStyle: { color: '#C74A6D', borderWidth: 1, borderColor: '#000' } },
                            { value: 0, name: '一般', itemStyle: { color: '#448EF7', borderWidth: 1, borderColor: '#000' } },
                        ],
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: 'rgba(0, 0, 0, 0.5)'
                            }
                        }
                    }
                ]
            }
        }
    },
    computed: {
        mapStyle() {
            let obj = {
                width: "100%",
                height: '100%'
            };
            return obj;
        },
    },
    mounted() {
        var that = this;
        this.$nextTick(() => {
            console.log("DangerPie: init echarts");
            this.chart1 = this.$echarts.init(this.$refs.mapContainer1);
            this.chart2 = this.$echarts.init(this.$refs.mapContainer2);
            window.onresize = function () {
                console.log("do resize");
                that.chart1.resize();
                that.chart2.resize();
            };
            this.chart1.setOption(this.chart1Option, false);
            this.chart2.setOption(this.chart2Option, false);
            // this.chart.on("click", this.onClick);
        });
    },
}
</script>

<style lang="scss" scoped></style>