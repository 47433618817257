<template>
    <div class="d-flex flex-column align-center w-full h-full wrapper">
        <v-dialog v-model="showDialog" transition="dialog-top-transition" max-width="1337px"
            content-class="dialog-internel" class="dialog-wrapper">
            <template v-slot:default="dialog">
                <div class="dialog-container">
                    <YTag :title="currentDock.dockName" type="primary" height="47px" paddingRight="100px"
                        class="white--text text-siyuan dialog-title"></YTag>
                    <v-toolbar color="background" dense flat class="dialog-toolbar"
                        style="border-bottom: 1px solid red;">
                        <v-spacer></v-spacer>
                        <div class="d-flex align-center h-full">
                            <v-btn icon @click="dialog.value = false" color="accent" small>
                                <v-icon :size="50"> {{ icons.mdiClose }}</v-icon>
                            </v-btn>
                        </div>
                    </v-toolbar>
                    <div class="dialog-content py-5 px-5">
                        <div class="dialog-content-row">
                            <div class="dialog-content-card">
                                <YTag title="码头五维评价" type="primary"
                                    class="white--text text-siyuan dialog-content-card-title"></YTag>
                                <div v-if="currentDock.five_dimension" class="h-full w-full pt-6 px-2">
                                    <FiveDimension :quwei="currentDock.five_quwei" :pinci="currentDock.five_pinci"
                                        :pingxiao="currentDock.five_pingxiao" :kaobo="currentDock.five_kaobo"
                                        :zonghe="currentDock.five_zonghe" />
                                </div>
                            </div>
                            <div class="dialog-content-middle">
                                <div class="dialog-content-card" style="height: 146px;">
                                    <YTag title="概况" type="primary"
                                        class="white--text text-siyuan dialog-content-card-title"></YTag>
                                    <div class="d-flex justify-space-around px-8 py-2" style="margin-top: 50px;">
                                        <div class="d-flex flex-column align-center justify-center">
                                            <div class="text-siyuan font-weight-bold text-h4">{{ yearVistors }}</div>
                                            <div class="text-siyuan-bold accent--text mt-1">年接待游客数</div>
                                        </div>
                                        <div class="d-flex flex-column align-center justify-center">
                                            <div class="text-siyuan font-weight-bold text-h4">{{ yearSchedules }}</div>
                                            <div class="text-siyuan-bold accent--text mt-1">年度航班数</div>
                                        </div>
                                        <div class="d-flex flex-column align-center justify-center">
                                            <div class="text-siyuan font-weight-bold text-h4">
                                                {{ currentDock.berthNumber }}</div>
                                            <div class="text-siyuan-bold accent--text mt-1">泊位总数</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="dialog-content-card" style="height: 158px;">
                                    <YTag title="综合通过能力" type="primary"
                                        class="white--text text-siyuan dialog-content-card-title"></YTag>
                                    <div class="d-flex flex-column px-4 py-4" style="margin-top: 50px;">
                                        <div class="d-flex align-center justify-space-around">
                                            <div class="text-siyuan-bold accent--text mt-1">每小时最大航班数<span
                                                    class="ml-3 text-siyuan-bold black--text">{{
            currentDock.schedules_per_hour }}</span>
                                            </div>
                                            <div class="text-siyuan-bold accent--text mt-1">每小时最大游客数<span
                                                    class="ml-3 text-siyuan-bold black--text">{{
            currentDock.customers_per_hour }}</span>
                                            </div>
                                        </div>
                                        <div class="d-flex align-center justify-space-around mt-4">
                                            <div class="text-siyuan-bold accent--text mt-1">入口<span
                                                    class="ml-3 text-siyuan-bold black--text">{{
            currentDock.entranceNumber }}</span>
                                            </div>
                                            <div class="text-siyuan-bold accent--text mt-1">安检<span
                                                    class="ml-3 text-siyuan-bold black--text">{{
            currentDock.securityDoorNumber }}</span>
                                            </div>
                                            <div class="text-siyuan-bold accent--text mt-1">验票<span
                                                    class="ml-3 text-siyuan-bold black--text">{{
            currentDock.ticketCheckerNumber }}</span>
                                            </div>
                                            <div class="text-siyuan-bold accent--text mt-1">引桥<span
                                                    class="ml-3 text-siyuan-bold black--text">{{
            currentDock.bridgeNumber }}</span>
                                            </div>
                                            <div class="text-siyuan-bold accent--text mt-1">候船厅<span
                                                    class="ml-3 text-siyuan-bold black--text">{{
            currentDock.areaOfWaitingArea }}㎡</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="dialog-content-card d-flex flex-column">
                                <div class="effeciency-title-wrapper" style="height: 4rem;flex:0 0 4rem">
                                    <YTag title="坪效" type="primary"
                                        class="white--text text-siyuan dialog-content-card-title"></YTag>
                                    <!-- <v-btn-toggle v-model="effeciencyModel" mandatory class="effeciency-btn" tile dense group
                                        color="primary accent-3">
                                        <v-btn value="year" primary>
                                            <span class="hidden-sm-and-down">按年</span>
                                        </v-btn>
                                        <v-btn value="month">
                                            <span class="hidden-sm-and-down">按月</span>
                                        </v-btn>
                                    </v-btn-toggle> -->
                                </div>
                                <div class="px-2" style='flex:1'>
                                    <Effeciency :dockCode="currentDock.dockCode" :type="effeciencyModel" />
                                </div>
                            </div>
                        </div>
                        <div class="dialog-content-row mt-3">
                            <div class="dialog-content-card">
                                <YTag title="区位" type="primary"
                                    class="white--text text-siyuan dialog-content-card-title"></YTag>
                                <div v-if="currentDock.areaPic.length" style="margin-top: 50px;" class="px-2 pt-2">
                                    <v-card :href="currentDock.areaPic[0].url" target="_blank" class="rounded-0">
                                        <v-img :src="currentDock.areaPic[0].url"></v-img>
                                    </v-card>
                                </div>
                            </div>
                            <div class="dialog-content-card">
                                <YTag title="靠泊能力" type="primary"
                                    class="white--text text-siyuan dialog-content-card-title"></YTag>
                                <div style="margin-top: 50px;" class="px-2 pt-2">
                                    <Berth />
                                </div>
                            </div>
                            <div class="dialog-content-card d-flex flex-column">
                                <div class="frequency-title-wrapper" style="height: 4rem;flex:0 0 4rem">
                                    <YTag title="利用频次" type="primary"
                                        class="white--text text-siyuan dialog-content-card-title"></YTag>
                                    <v-btn-toggle v-model="freqModel" mandatory class="frequency-btn" tile dense group
                                        color="primary accent-3">
                                        <v-btn value="year" primary>
                                            <span class="hidden-sm-and-down">今年</span>
                                        </v-btn>
                                        <v-btn value="month">
                                            <span class="hidden-sm-and-down">本月</span>
                                        </v-btn>
                                    </v-btn-toggle>
                                </div>
                                <div class="px-2" style='flex:1'>
                                    <Frequency :dockCode="currentDock.dockCode" :type="freqModel" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </v-dialog>
        <div class="tabs">
            <v-item-group class="d-flex" mandatory :value='currentTab' @change="onTabChange">
                <div class="tab" v-for="item in tabs" :key="item.name">
                    <v-item v-slot="{ active, toggle }" :value="item.name">
                        <YTag :title="`${item.name} ${item.count}`" :type="active ? 'primary' : 'secondary'"
                            class="white--text text-siyuan" @click="onTabChange(item)"></YTag>
                    </v-item>
                </div>
            </v-item-group>
        </div>
        <div class="map-legend d-flex flex-column justify-space-around px-2 py-2">
            <div v-for="category in categoryList" :key="category.type" class="d-flex align-center">
                <div class="ml-2 map-legend-circle" :style="{ backgroundColor: category.color }"></div>
                <div class="ml-2">{{ category.name }}</div>
                <div class="ml-2">{{ category.countStr }}</div>
            </div>
        </div>
        <div class="map-footer d-flex justify-space-around align-center">
            <div v-for="category in categoryList" :key="category.type" class="d-flex flex-column justify-space-around">
                <div class="d-flex mb-2">
                    <YTag :title="category.name" :type="category.typeStr" />
                </div>
                <div class="map-footer-content d-flex">
                    <v-img :src="require(`@/assets/images/yma/${category.typeStr}.png`)" width="6rem" height="6.1rem">
                        <div class="text-center text-h4 font-weight-bold text-mama"
                            :style="{ color: category.fontColor }">
                            {{
            category.count }}</div>
                    </v-img>
                    <div class="map-footer-content-detail font-weight-bold ml-2"
                        :style="{ color: category.fontColor, fontSize: '1rem', width: '8rem' }">
                        <!-- line1 -->
                        <div class="d-flex justify-space-between">
                            <div>岸线长度</div>
                            <div class="map-footer-content-detail-col2">{{ category.length }}m</div>
                        </div>
                        <!-- line2 -->
                        <div v-if="category.typeStr == 'youting'" class="d-flex justify-space-between">
                            <div>泊位数</div>
                            <div class="map-footer-content-detail-col2">{{ category.berthNumber }}个</div>
                        </div>
                        <div v-else class="d-flex justify-space-between">
                            <div>趸船</div>
                            <div class="map-footer-content-detail-col2">{{ category.berthNumber }}个</div>
                        </div>
                        <!-- line3 -->
                        <div v-if="category.typeStr == 'youting'" class="d-flex justify-space-between">
                            <div>入驻俱乐部</div>
                            <div class="map-footer-content-detail-col2">{{ category.clubNumber }}个</div>
                        </div>
                        <div v-else class="d-flex justify-space-between">
                            <div>引桥</div>
                            <div class="map-footer-content-detail-col2">{{ category.bridgeNumber }}个</div>
                        </div>
                        <!-- line4 -->
                        <div v-if="category.typeStr != 'youting'" class="d-flex justify-space-between">
                            <div>防汛们</div>
                            <div class="map-footer-content-detail-col2">{{ category.berthNumber }}个</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div id="map" ref="mapContainer" :style="mapStyle"></div>
    </div>
</template>

<script>
import { mdiClose } from "@mdi/js";
import 'echarts/extension/bmap/bmap';
import YTag from '@/components/YTag/YTag'
import CUSTOM_STYLE from './mapstyle'
import { getDocks } from '@/api/resource'
import { mdapi } from "@/api/mdapi";
import FiveDimension from "./FiveDimension";
import Frequency from "./Frequency";
import Effeciency from "./Effeciency";
import Berth from "./Berth";
import { getYearInfo } from '../../api/resource'
const mapData = [
    { name: '码头1', value: [121.519886, 31.222233, 10] },
    { name: '码头2', value: [121.515143, 31.205555, 10] }
]
export default {
    components: {
        YTag,
        FiveDimension,
        Frequency,
        Effeciency,
        Berth
    },
    props: {
        label: {
            type: String,
            default: "",
        },
        labelIcon: {
            type: String,
            default: "",
        },
        name: {
            type: String,
            default: "",
        },
        xAxisName: {
            type: String,
            default: "",
        },
        height: {
            type: Number,
            default: 200,
        },
        color: {
            type: String,
            default: "#1C3D74",
        },
        seriesData: {
            type: Array,
            default: () => {
                return [];
            },
        },
    },
    data() {
        return {
            icons: {
                mdiClose,
            },
            showDialog: false,
            currentTab: '规划布点',
            currentDock: null,
            yearVistors: 'N/A',  //年度客流
            yearSchedules: 'N/A',  //年度航班
            // 坪效模式
            effeciencyModel: 'year',
            // 利用频次模式
            freqModel: 'year',
            tabs: [
                { name: '规划布点', count: 0 },
                { name: '已建', count: 0 },
                { name: '在建', count: 0 },
                { name: '运管', count: 0 }
            ],
            categoryList: [
                {
                    name: '游船码头',
                    type: '游船',
                    typeStr: 'youchuan',
                    color: '#00D3F3',
                    fontColor: '#0571B6',
                    count: '',
                    countStr: '',
                    total: 0,
                    length: 0,  //岸线长度
                    berthNumber: 0,  //泊位/趸船数量
                    bridgeNumber: 0,   //引桥个数
                    gateCount: 0,  //防汛们数量
                    clubNumber: 0  //入住俱乐部
                },
                {
                    name: '游艇码头',
                    type: '游艇',
                    typeStr: 'youting',
                    color: '#F8C133',
                    fontColor: '#A8842E',
                    count: '',
                    countStr: '12',
                    total: 0,
                    length: 0,  //岸线长度
                    berthNumber: 0,  //泊位/趸船数量
                    bridgeNumber: 0,   //引桥个数
                    gateCount: 0,  //防汛们数量
                    clubNumber: 0  //入住俱乐部
                },
                {
                    name: '公务码头',
                    type: '公务',
                    typeStr: 'gongwu',
                    color: '#00E160',
                    fontColor: '#1F945D',
                    count: '',
                    countStr: '2基7站',
                    total: 0,
                    length: 0,  //岸线长度
                    berthNumber: 0,  //泊位/趸船数量
                    bridgeNumber: 0,   //引桥个数
                    gateCount: 0,  //防汛们数量
                    clubNumber: 0  //入住俱乐部
                },
                {
                    name: '轮渡码头',
                    type: '轮渡',
                    typeStr: 'lundu',
                    // color: '#F4F144',
                    color: '#FFEF59',
                    fontColor: '#afad27',
                    count: '',
                    countStr: '12',
                    total: 0,
                    length: 0,  //岸线长度
                    berthNumber: 0,  //泊位/趸船数量
                    bridgeNumber: 0,   //引桥个数
                    gateCount: 0,  //防汛们数量
                    clubNumber: 0  //入住俱乐部
                }
            ],
            dockList: [],
            currentList: [],
            chart: null,
            chartOption: {
                // tooltip: {
                //     triggerOn: "click",
                //     show: false
                // },
                tooltip: {
                    trigger: 'item',
                    formatter: '{b}',
                },
                bmap: {
                    center: [121.500543, 31.2009], // 地图的中心点坐标
                    zoom: 13, // 地图的缩放级别
                    roam: true,// 启用缩放和漫游
                    // mapStyle: {
                    //     styleJson: CUSTOM_STYLE
                    // },
                    mapStyleV2: {
                        //自己在百度地图生成的个性化样式id
                        styleId: 'e8acab8e820e9e18b6b8b6a16b0370a4'
                    }
                },
                series: [
                    {
                        type: 'scatter', // 使用散点图
                        coordinateSystem: 'bmap', // 使用百度地图坐标系
                        data: mapData,
                        symbolSize: 20, // 数据点的大小
                        itemStyle: {
                            color: 'red' // 数据点的颜色
                        }
                    }
                ]
            },
        };
    },
    computed: {
        mapStyle() {
            let obj = {
                width: "100%",
                height: this.$vuetify.breakpoint.xs
                    ? this.height * 2 + "px"
                    : this.height + "px",
            };
            obj.height = '100%'
            return obj;
        },
    },
    created() {
        console.log("created");
    },
    mounted() {
        var that = this;
        this.$nextTick(() => {
            console.log("BgMap: init echarts");
            that.chart = this.$echarts.init(this.$refs.mapContainer);
            this.chart.on("click", this.onClick);
            window.onresize = function () {
                that.chart.resize();
            };
            that.refresh();
        });
    },
    watch: {
        seriesData: {
            handler: function (list) {
                this.refresh();
            },
            immediate: true,
        },
    },
    methods: {
        async refresh() {
            if (this.chart) {
                console.log('first setOption')
                this.chart.setOption(this.chartOption, true);
            }
            let result = await getDocks()
            if (result.success) {
                this.dockList = result.data
                console.log('docks:', this.dockList)
                // 统计每个tab的count
                let counts = [0, 0, 0, 0]
                for (let i in this.categoryList) {
                    this.categoryList[i].total = 0
                }
                let base = 0
                for (let i in this.dockList) {
                    let dock = this.dockList[i]
                    let category = this.categoryList.find(e => e.type == dock.category)
                    if (category) {
                        if (category.type == '公务' && dock.dockName.endsWith('基地')) {
                            // 基站单独算
                            base++
                        } else {
                            category.total++
                        }
                    }
                    // 五维图
                    if (dock.five_quwei && dock.five_pingxiao &&
                        dock.five_pinci && dock.five_kaobo && dock.five_zonghe) {
                        dock.five_dimension = true
                    } else {
                        dock.five_dimension = false
                    }
                    // 区位
                    dock.areaPic = mdapi.getFileList(dock.area_pic)
                    if (dock.plan_status == '规划内') {
                        counts[0]++
                    }
                    if (dock.construct_status == '已建') {
                        counts[1]++
                    }
                    if (dock.construct_status == '在建') {
                        counts[2]++
                    }
                    if (dock.incharge == '是') {
                        counts[3]++
                    }
                }
                if (base > 0) {
                    this.categoryList[2].total = `${base}基${this.categoryList[2].total}站`
                }
                for (let i in this.tabs) {
                    this.tabs[i].count = counts[i]
                }
                this.doFilter()
            }
        },
        doFilter() {
            var that = this
            let result = [];
            let dataset = []
            result = this.dockList.filter((item) => {
                if (!item.baidu) {
                    return false
                }
                if (that.currentTab == '规划布点') {
                    if (item.plan_status == '规划内') {
                        return true
                    } else {
                        return false
                    }
                } else if (that.currentTab == '运管') {
                    if (item.incharge == '是') {
                        return true
                    } else {
                        return false
                    }
                } else {
                    // 已建 在建
                    if (item.construct_status == that.currentTab) {
                        return true
                    } else {
                        return false
                    }
                }
            });
            let base = 0
            for (let i in this.categoryList) {
                let item = this.categoryList[i]
                item.count = 0
                item.length = 0
                item.berthNumber = 0
                item.bridgeNumber = 0
                item.clubNumber = 0
            }
            dataset = result.map(item => {
                let gps = item.baidu.split(',')
                let color = '#00D3F3'
                let category = that.categoryList.find(e => e.type == item.category)
                if (category) {
                    color = category.color
                    if (category.type == '公务' && item.dockName.endsWith('基地')) {
                        // 基站单独算
                        base++
                    } else {
                        category.count++
                    }
                    category.length += parseInt(item.shoreLength)
                    if (item.berthNumber) {
                        category.berthNumber += parseInt(item.berthNumber)
                    }
                    if (item.bridgeNumber) {
                        category.bridgeNumber += parseInt(item.bridgeNumber)
                    }
                    if (item.dock_club_num) {
                        category.clubNumber += parseInt(item.dock_club_num)
                    }
                }

                let obj = {
                    name: item.dockName,
                    value: [gps[0], gps[1], 20, item.rowid],
                    itemStyle: {
                        color,
                        shadowColor: 'rgba(0, 0, 0, 0.25)',
                        shadowBlur: 8,
                        shadowOffsetY: 8
                    }
                }
                return obj
            })
            this.categoryList[0].countStr = this.categoryList[0].count
            this.categoryList[1].countStr = this.categoryList[1].count
            this.categoryList[3].countStr = this.categoryList[3].count
            if (base > 0) {
                this.categoryList[2].countStr = `${base}基${this.categoryList[2].count}站`
                this.categoryList[2].count += base
            } else {
                this.categoryList[2].countStr = this.categoryList[2].count
            }
            // 更新列表
            this.currentList = result;
            // 更新地图
            this.chartOption.series[0].data = dataset
            this.chart.setOption(this.chartOption, false);
        },
        onTabChange(item) {
            console.log("onTabChange", item)
            this.currentTab = item.name
            this.doFilter()
        },
        onClick(params) {
            // this.$emit("seriesClick", params.name);
            let rowid = params.data.value[3]
            this.currentDock = this.dockList.find(e => e.rowid == rowid)
            this.showDialog = true
            this.fetchYearInfo(this.currentDock.dockCode)
            // console.log(rowid, params)
        },
        async fetchYearInfo(dockCode) {
            this.yearVistors = 'N/A'
            this.yearSchedules = 'N/A'
            if (!dockCode) {
                return
            }
            let result = await getYearInfo(dockCode)
            if (result.success) {
                this.yearSchedules = result.data.data[0].totol_sched
                this.yearVistors = result.data.data[0].total_visitor
            }
        }
    },
};
</script>

<style lang="scss">
.dialog-internel {
    border-radius: 0px;
    box-shadow: 8px 8px 8px 0px rgba(0, 0, 0, 0.25) !important;
    max-height: 96% !important;
}

.dialog-container {
    position: relative;
    background-color: var(--v-background-base);
    border: 1px solid rgb(27, 114, 185);


    .dialog-title {
        position: absolute;
        z-index: 10;
        font-size: 24px;
        top: 8px;
        left: 8px;
    }

    .dialog-content {
        display: flex;
        flex-direction: column;

        .dialog-content-row {
            display: flex;
            justify-content: space-between;

            .dialog-content-middle {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
            }

            .dialog-content-card {
                position: relative;
                width: 422px;
                height: 316px;
                background-color: #E0E6E7;
                box-shadow: 8px 8px 8px 0px rgba(0, 0, 0, 0.25) !important;

                .dialog-content-card-title {
                    position: absolute;
                    top: 10px;
                    left: 10px;
                }

                .frequency-btn,
                .effeciency-btn {
                    position: absolute;
                    top: 10px;
                    right: 10px;
                }
            }
        }
    }

    .v-toolbar__content {
        border-bottom: 1px solid rgb(27, 114, 185);

    }
}
</style>

<style lang="scss" scoped>
.wrapper {
    position: relative;

    .tabs {
        position: absolute;
        top: 20px;
        left: 20px;
        z-index: 100;

        .tab {
            margin-left: 16px;
            cursor: pointer;
        }
    }

    .map-legend {
        position: absolute;
        bottom: 45%;
        right: 1.125rem;
        height: 12rem;
        width: 13rem;
        z-index: 100;
        background-color: rgba(224, 230, 231, 0.5);
        box-shadow: 6px 6px 6px 0px rgba(0, 0, 0, 0.25) !important;
        font-family: 'siyuan';
        font-weight: bold;
        font-size: 1.125rem;

        .map-legend-circle {
            width: 1.6rem;
            height: 1.6rem;
            border-radius: 50%;
            box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.25) !important;
        }
    }

    .map-footer {
        position: absolute;
        bottom: 1.125rem;
        left: 1.125rem;
        right: 1.125rem;
        height: 10rem;
        width: auto;
        z-index: 100;
        background-color: rgba(224, 230, 231, 0.75);
        box-shadow: 8px 8px 8px 0px rgba(0, 0, 0, 0.25) !important;

        .map-footer-content-detail-col2 {
            margin-left: 0.75rem;
        }
    }
}
</style>