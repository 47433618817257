import {
    deepClone,
    getFileInfo
} from "./utils.js"

const mdapi = {
    SpliceType: {
        AND: 1,
        OR: 2
    },
    DataType: {
        TEXT: 2, //文本框
        PHONE: 3, //手机
        EMAIL: 5, //邮件地址
        NUMBER: 6, //数值 Double
        MONEY: 8, //金额 Double
        SINGLE_FLAT: 9, //单选平铺
        MULTIPLE: 10, //多选 List["String"]
        SINGLE_DROPDOWN: 11, //单选下拉
        ATTACHMENT: 14, //附件
        DATE: 15, //日期 String（2018-8-8）
        DATETIME: 16, //日期和时间 String（2018-8-8 12:00:00）
        SEGMENT: 22, //分段
        AREA: 24, //地区 省市县之间以“/”隔开，如：江西省/上饶市/铅山县
        MONEY_WORDS: 25, //大写金额
        MEMBER: 26, //成员 人员ID,多个用逗号（,）分割
        STAR: 28, //等级 int
        RELATE: 29, //关联表 String字符串，多条记录rowId用逗号(,)分隔。全量覆盖操作
        RELATE_FIELD: 30, //他表字段 只读
        FORMULA: 31, //公式 只读
        COMBO: 32, //文本组合
        AUTOCODE: 33, //自动编号
        CHILD_TABLE: 34, //子表
        CHECKBOX: 36, //Int（1:选中 0:未选中）
        COUNT: 37, //汇总
        LOCATION: 40, //定位 {x:"经度",y:"纬度",address:"地址",title:"摘要"}
        RICHTEXT: 41, //富文本
        SIGN: 42, //签名
        NOTE: 10010 //备注
    },
    FilterType: {
        DEFAULT: 0,
        LIKE: 1, //包含
        EQ: 2, //是（等于）
        START: 3, //开头为
        END: 4, //结尾为
        NCONTAIN: 5, //不包含
        NE: 6, //不是（不等于）
        ISNULL: 7, //为空
        HASVALUE: 8, //不为空
        BETWEEN: 11, //在范围内
        NBETWEEN: 12, //不在范围内
        GT: 13, //>
        GTE: 14, //>=
        LT: 15, //<
        LTE: 16, //<=
        DATEENUM: 17, //日期是
        NDATEENUM: 18, //日期不是
        MYSELF: 21, //我拥有的
        UNREAD: 22, //未读
        SUB: 23, //下属
        RCEQ: 24, //关联控件是
        RCNE: 25, //关联控件不是
        ARREQ: 26, //数组等于
        ARRNE: 27 //数组不等于
    },
    getRelationSid(value) {
        let sid = ""
        if (value) {
            let tmplist = JSON.parse(value)
            if (tmplist.length) {
                sid = tmplist[0].sid
            }
        }
        return sid
    },
    getRelationName(value) {
        let name = ""
        if (value) {
            let tmplist = JSON.parse(value)
            if (tmplist.length) {
                name = tmplist[0].name
            }
        }
        return name
    },
    getFileList(value) {
        let fileList = []
        if (value) {
            let tmpList = JSON.parse(value)
            for (let i in tmpList) {
                let file = tmpList[i]
                let index = file.file_name.lastIndexOf(".");
                let ext = file.file_name.substring(index + 1)
                // let url = file.large_thumbnail_path + file.large_thumbnail_name
                let url = ""
                if (file.file_type == 1) {
                    //image
                    url = file.large_thumbnail_path + file.large_thumbnail_name
                } else if (file.file_type == 2) {
                    //file
                    // url = file.original_file_full_path
                    url = file.DownloadUrl
                }
                let fileInfo = getFileInfo(file.file_name, url)
                // console.log("fileurl:", file.file_name, url)
                let obj = {
                    name: file.file_name,
                    extname: ext,
                    url,
                    preview: fileInfo.preview
                }
                fileList.push(obj)
            }
        }
        return fileList
    },
    //获取表结构
    getWorksheetInfo(urlprefix, appKey, sign, worksheetId) {
        return new Promise((resolve, reject) => {
            fetch(urlprefix + '/v2/open/worksheet/getWorksheetInfo', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    appKey,
                    sign,
                    worksheetId
                })
            })
                .then((response) => response.json())
                .then((responseJson) => {
                    resolve(responseJson)
                })
                .catch((error) => {
                    console.error(error);
                    reject(error)
                });
        }) // end return new Promise((resolve, reject) => {
    },
    //获取列表
    getFilterRows(urlprefix, appKey, sign, worksheetId, filters, pageSize, pageIndex = 1, viewId = "", sortId = "",
        isAsc = false, notGetTotal = true) {
        return new Promise((resolve, reject) => {
            fetch(urlprefix + '/v2/open/worksheet/getFilterRows', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    appKey,
                    sign,
                    worksheetId,
                    pageSize,
                    pageIndex,
                    viewId,
                    sortId,
                    isAsc,
                    filters,
                    notGetTotal
                })
            })
                .then((response) => response.json())
                .then((responseJson) => {
                    resolve(responseJson)
                })
                .catch((error) => {
                    console.error(error);
                    reject(error)
                });

        }) // end return new Promise((resolve, reject) => {
    },

    //获取所有列表
    getFilterRowsAll(urlprefix, appKey, sign, worksheetId, filters, viewId = "", sortId = "", isAsc = false) {
        return new Promise(async (resolve, reject) => {
            let pageSize = 1000
            let pageIndex = 1
            let res = null
            let result = {}
            let notGetTotal = false

            res = await this.getFilterRows(urlprefix, appKey, sign, worksheetId, filters, pageSize, pageIndex, viewId, sortId,
                isAsc, notGetTotal)
                .catch(err => {
                    reject(err)
                    return
                })

            if (!res) {
                return
            }

            if (res.errmsg) {
                reject(res)
                return
            }
            result = deepClone(res)
            if (result.data.total > result.data.rows.length) {
                //没拿完
                let pageTotal = (result.data.total - 1) / pageSize + 1
                for (let i = pageIndex; i < pageTotal; i++) {
                    res = await this.getFilterRows(urlprefix, appKey, sign, worksheetId, filters, pageSize, i + 1, viewId,
                        sortId, isAsc, notGetTotal)
                        .catch(err => {
                            reject(err)
                            return
                        })

                    if (!res) {
                        return
                    }

                    if (res.errmsg) {
                        reject(res)
                        return
                    }
                    result.data.rows.push(...res.data.rows)
                }
            }

            resolve(result)
        })
    },

    //新建行记录
    addRow(urlprefix, appKey, sign, worksheetId, controls) {
        return new Promise((resolve, reject) => {
            fetch(urlprefix + '/v2/open/worksheet/addRow', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    appKey: appKey,
                    sign: sign,
                    worksheetId: worksheetId,
                    controls: controls
                })
            })
                .then((response) => response.json())
                .then((responseJson) => {
                    resolve(responseJson)
                })
                .catch((error) => {
                    console.error(error);
                    reject(error)
                });
        })
    }, // end addRow

    //更新行记录
    editRow(urlprefix, appKey, sign, worksheetId, rowId, controls) {
        return new Promise((resolve, reject) => {
            fetch(urlprefix + '/v2/open/worksheet/editRow', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    appKey: appKey,
                    sign: sign,
                    worksheetId: worksheetId,
                    rowId: rowId,
                    controls: controls
                })
            })
                .then((response) => response.json())
                .then((responseJson) => {
                    resolve(responseJson)
                })
                .catch((error) => {
                    console.error(error);
                    reject(error)
                });
        })
    }, // end editRow

    //删除行记录
    deleteRow(urlprefix, appKey, sign, worksheetId, rowId) {
        return new Promise((resolve, reject) => {
            fetch(urlprefix + '/v2/open/worksheet/deleteRow', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    appKey: appKey,
                    sign: sign,
                    worksheetId: worksheetId,
                    rowId: rowId
                })
            })
                .then((response) => response.json())
                .then((responseJson) => {
                    resolve(responseJson)
                })
                .catch((error) => {
                    console.error(error);
                    reject(error)
                });
        })
    }, // end deleteRow

    //根据rowid获取记录
    getRowById(urlprefix, appKey, sign, worksheetId, rowId) {
        return new Promise((resolve, reject) => {

            const params = {
                appKey,
                sign,
                worksheetId,
                rowId
            };
            const queryString = Object.keys(params)
                .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
                .join('&');

            fetch(urlprefix + '/v2/open/worksheet/getRowById?' + queryString, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                }
            })
                .then((response) => response.json())
                .then((responseJson) => {
                    resolve(responseJson)
                })
                .catch((error) => {
                    console.error(error);
                    reject(error)
                });
        })
    }, // end getRowById

    //根据rowid获取记录
    getRowRelations(urlprefix, appKey, sign, worksheetId, rowId, controlId, pageSize, pageIndex = 1) {
        return new Promise((resolve, reject) => {
            fetch(urlprefix + '/v2/open/worksheet/getRowRelations', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    appKey,
                    sign,
                    worksheetId,
                    rowId,
                    controlId,
                    pageSize,
                    pageIndex
                })
            })
                .then((response) => response.json())
                .then((responseJson) => {
                    resolve(responseJson)
                })
                .catch((error) => {
                    console.error(error);
                    reject(error)
                });
        })
    }, // end getRowById

}


export {
    mdapi
}
