<template>
    <div id="map" ref="mapContainer" :style="mapStyle"></div>
</template>

<script>
import { fontSize } from '../../utils/utils'
import { getFrequencyYear, getFrequencyMonth } from '../../api/resource'
export default {
    props: {
        dockCode: {
            type: String,
            default: "",
        },
        type: {
            type: String,
            default: "year",
        }
    },
    data() {
        return {
            chart: null,
            yearAxis: [],
            monthAxis: [],
            yearData: [],
            monthData: [],
            chartOption: {
                color: ['#3580E0'],
                legend: {
                    top: '3%',
                    right: '3%',
                    icon: 'rect',
                    itemWidth: 14,
                    data: [
                        {
                            name: '靠泊次数',
                            itemStyle: {
                                color: new this.$echarts.graphic.LinearGradient(
                                    0, 0, 0, 1,
                                    [
                                        { offset: 0, color: 'rgba(0, 144, 251)' },
                                        { offset: 1, color: 'rgba(189, 217, 241)' }
                                    ]
                                )
                            }
                        }
                    ]
                },
                tooltip: {
                    trigger: 'axis',
                    formatter: '{b0}月<br/>靠泊次数:{c0}'
                },
                grid: {
                    bottom: '20%',
                    top: '30%'
                },
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    axisLabel: {
                        fontSize: fontSize(10)
                    },
                    data: ['23/11', '23/12', '24/01', '24/02', '24/03', '24/04', '24/05', '24/06']
                },
                yAxis: [{
                    type: 'value',
                    name: '次数',
                    nameTextStyle:{
                        fontSize: fontSize(14),
                        align: 'right'
                    },
                    position: 'left',
                    axisLabel: {
                        fontSize: fontSize(12),
                    }
                }, {
                    type: 'value',
                    name: '',
                    position: 'right',
                    alignTicks: true,
                    axisLabel: {
                        align: 'right',
                        margin: fontSize(30),
                        fontSize: fontSize(12),
                        formatter: '{value}%'
                    }
                }],
                series: [
                    {
                        type: 'line',
                        yAxisIndex: 0,
                        smooth: true,
                        name: '靠泊次数',
                        showSymbol: false,
                        data: [5, 2, 3, 6, 8, 10, 9, 8],
                        areaStyle: {
                            color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                                offset: 0,
                                color: 'rgba(0, 144, 251, 0.8)'
                            }, {
                                offset: 1,
                                color: 'rgba(189, 217, 241, 0.8)'
                            }])
                        }
                    }
                ]
            }
        }
    },
    watch: {
        dockCode: {
            handler: function (newvalue, oldvalue) {
                console.log('dockCode handler', newvalue, oldvalue)
                if (oldvalue && newvalue) {
                    this.refresh();
                }
            },
            immediate: true,
        },
        type: {
            handler: function (value) {
                this.redraw();
            },
            immediate: true,
        }
    },
    computed: {
        mapStyle() {
            let obj = {
                width: "100%",
                height: '100%'
            };
            return obj;
        },
    },
    mounted() {
        var that = this;
        this.$nextTick(() => {
            console.log("Frequency: init echarts, dockCode:", this.dockCode);
            this.chart = this.$echarts.init(this.$refs.mapContainer);
            window.onresize = function () {
                console.log("do resize");
                that.chart.resize();
            };
            this.refresh()
            // this.chart.on("click", this.onClick);
        });
    },
    methods: {
        async refresh() {
            let result = await getFrequencyYear(this.dockCode)
            // console.log('result:', result)
            // 今年
            if (result.success) {
                this.yearAxis = result.data.key.map(item => {
                    let arr = item.split('/')
                    if (arr.length) {
                        return arr[1]
                    } else {
                        return item
                    }
                })
                this.yearData = result.data.value
            } else {
                this.yearAxis = []
                this.yearData = []
            }
            // 本月
            result = await getFrequencyMonth(this.dockCode)
            if (result.success) {
                this.monthAxis = result.data.key.map(item => {
                    let arr = item.split('/')
                    if (arr.length) {
                        return arr[1]
                    } else {
                        return item
                    }
                })
                this.monthData = result.data.value
            } else {
                this.monthAxis = []
                this.monthData = []
            }
            this.redraw()
        },
        redraw() {
            console.log('redraw type:', this.type, this.dockCode)
            if (this.type == 'year') {
                this.chartOption.xAxis.data = this.yearAxis
                this.chartOption.series[0].data = this.yearData
                this.chartOption.tooltip.formatter = '{b0}月<br/>靠泊次数:{c0}'
            } else {
                this.chartOption.xAxis.data = this.monthAxis
                this.chartOption.series[0].data = this.monthData
                this.chartOption.tooltip.formatter = '{b0}日<br/>靠泊次数:{c0}'
            }
            if (this.chart) {
                this.chart.setOption(this.chartOption, false);
            }
        }
    }
}
</script>

<style lang="scss" scoped></style>