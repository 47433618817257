<template>
    <div class="h-full d-flex flex-column">
        <div class="d-flex px-2 pt-2 pb-1 justify-space-between align-center">
            <YTag title="关注风险与预警" />
            <div class="d-flex align-center">
                <v-select v-model="currentYear" class="alarm-input flex-grow-0" :items="years" outlined dense single-line hide-details
                    style="width: 6.5rem" @change="onDateChange"></v-select>
                <v-select v-model="currentMonth" class="alarm-input flex-grow-0 ml-1" :items="months[currentYear]" outlined single-line dense hide-details
                    style="width: 5.4rem" @change="onDateChange"></v-select>
            </div>
        </div>
        <div class="d-flex justify-space-between px-6">
            <div class="text-subtitle-2">分级预警<span class="ml-3 text-h6">{{ fengji }}</span></div>
            <div class="d-flex text-caption">
                <div class="d-flex justify-space-between  align-center">
                    <div class="pr-4">同比</div>
                    <div :class="tongbi > 0 ? 'show-green' : 'show-red'">{{ tongbi }}%</div>
                </div>
                <div class="d-flex justify-space-between  align-center ml-5">
                    <div class="pr-4">环比</div>
                    <div :class="huanbi > 0 ? 'show-green' : 'show-red'">{{ huanbi }}%</div>
                </div>
            </div>
        </div>
        <v-row class="fill-height px-3" align="center" justify="center" no-gutters>
            <v-col v-for="item in items" :key="item.name" cols="4" align="center" class="py-1">
                <div class="text-h6 font-weight-bold"><span :style="{ color: item.color }" class="text-h5">{{
                    item.current }}</span>/{{ item.total }}</div>
                <v-img :src="item.icon" height="3.875rem" width="3.875rem">
                </v-img>
                <div class="text-subtitle-2 mt-2">{{ item.name }}</div>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import { mdiCircle } from "@mdi/js";
import YTag from '../YTag/YTag'
export default {
    components: { YTag },
    data() {
        return {
            icons: {
                mdiCircle,
            },
            currentYear: '2024年',
            currentMonth: '04月',
            years: ['2023年', '2024年'],
            months: { '2023年': ['01月', '02月', '03月', '04月', '05月', '06月', '07月', '08月', '09月', '10月', '11月', '12月'], '2024年': ['01月', '02月', '03月', '04月'] },
            fengji: 0,
            tongbi: 1,
            huanbi: -1,
            items: [{
                name: '红灯预警',
                color: '#FF4425',
                icon: require('@/assets/images/yma/lights-red.png'),
                current: 1,
                total: 3
            }, {
                name: '黄灯预警',
                color: '#FFD035',
                icon: require('@/assets/images/yma/lights-yellow.png'),
                current: 1,
                total: 3
            }, {
                name: '集团督办',
                color: '#008EFB',
                icon: require('@/assets/images/yma/lights-blue.png'),
                current: 1,
                total: 3
            }],
        }
    },
    mounted() {

    },
    methods: {
        onDateChange(){
            console.log('date:', this.currentYear, this.currentMonth)
        }
    }
}
</script>

<style lang="scss" scoped>
.show-red {
    color: rgb(255, 69, 13)
}

.show-green {
    color: rgb(33, 135, 73)
}

.alarm-input ::v-deep{
    font-size: 1rem;
    .v-input__slot {
        padding-left: 0.5rem !important;
        padding-right: 0px !important;
    }
}
</style>