import { mdapi } from "./mdapi";

const URLPREFIX = 'http://app.huangpurivercrusies.com:9001/api'
const APPKEY = '4855a8cd88399b0e'
const SIGN = 'ZjczYTFmMDlkMDFhYjFkZTBkZTdkMmU1MTYwNzAxNTIzMjFhYjE5NzU4MTdkODhlM2NmMDUzYmUwZWQzNGQ0OA=='
const CAMERA_WSID = '6414626f1a71bb406e78b629'
const TARGET_WSID = '661c8e387667fc304b6746f3' //安全体征

// 安全体征
const getTargets = () => {
    return new Promise(async (resolve, reject) => {
        let result = {
            success: false,
            message: '',
            data: ''
        }
        try {
            let res = null
            let rowid = ''

            let filters = [{
                "controlId": "dock",
                "dataType": mdapi.DataType.RELATE,
                "spliceType": mdapi.SpliceType.AND,
                "filterType": mdapi.FilterType.HASVALUE,
                "value": '1'
            }, {
                "controlId": "value",
                "dataType": mdapi.DataType.NUMBER,
                "spliceType": mdapi.SpliceType.AND,
                "filterType": mdapi.FilterType.HASVALUE,
                "value": '1'
            }]
            let pageSize = 100
            let pageIndex = 1
            let sortId = 'issueDate'
            let isAsc = false
            res = await mdapi.getFilterRows(URLPREFIX, APPKEY, SIGN, TARGET_WSID, filters, pageSize, pageIndex, '', sortId, isAsc).catch(e => {
                console.log("getTargets error:", e)
                result.message = e.toString()
            })

            if (!res) {
                resolve(result)
                return
            }

            console.log("res is:", res)
            if (!res.success || !res.data) {
                // md error
                result.message = '网络错误'
                resolve(result)
                return
            }
            let data = []
            for (let i in res.data.rows) {
                let item = res.data.rows[i]
                let existItem = data.find(e => e.dockCode == item.dockCode)
                if (existItem) continue
                data.push(item)
            }
            data.sort((a, b) => {
                if (parseInt(a.stationIndex) == parseInt(b.stationIndex)) {
                    return a.dockName.localeCompare(b.dockName)
                } else {
                    return parseInt(a.stationIndex) - parseInt(b.stationIndex)
                }
            })
            result.success = true
            result.data = data
            resolve(result)
        } catch (error) {
            result.message = error.toString()
            resolve(result)
        }
    })
}

// 摄像头
const getCameras = () => {
    return new Promise(async (resolve, reject) => {
        let result = {
            success: false,
            message: '',
            data: ''
        }
        try {
            let res = null
            let rowid = ''

            let filters = [{
                "controlId": "is_freeze",
                "dataType": mdapi.DataType.CHECKBOX,
                "spliceType": mdapi.SpliceType.AND,
                "filterType": mdapi.FilterType.EQ,
                "value": '0'
            }, {
                "controlId": "show",
                "dataType": mdapi.DataType.CHECKBOX,
                "spliceType": mdapi.SpliceType.AND,
                "filterType": mdapi.FilterType.EQ,
                "value": '1'
            }]
            let pageSize = 1
            let pageIndex = 1
            let sortId = 'autono'
            let isAsc = true
            res = await mdapi.getFilterRowsAll(URLPREFIX, APPKEY, SIGN, CAMERA_WSID, filters, '', sortId, isAsc).catch(e => {
                console.log("getCameras error:", e)
                result.message = e.toString()
            })

            if (!res) {
                resolve(result)
                return
            }

            // console.log("res is:", res)
            if (!res.success || !res.data) {
                // md error
                result.message = '网络错误'
                resolve(result)
                return
            }
            result.success = true
            result.data = res.data.rows
            resolve(result)
        } catch (error) {
            result.message = error.toString()
            resolve(result)
        }
    })
}

export { getTargets, getCameras };