<template>
  <div class="h-full w-full">
    <v-dialog v-model="eventDialog" width="400" persistent>
      <v-card color="white" id="card-event">
        <v-card-title class="text-h5 grey lighten-2">
          {{ eventDialogTitle }}
        </v-card-title>

        <v-card-text class="text-body-1">
          <v-row>
            <v-col>
              <v-menu v-model="menuStart" :close-on-content-click="false" :nudge-right="40"
                transition="scale-transition" offset-y min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="eventStart" label="请选择起始日期" :prepend-icon="icons.mdiCalendar" readonly
                    v-bind="attrs" v-on="on"></v-text-field>
                </template>
                <v-date-picker v-model="eventStart" locale="en" @input="menuStart = false"></v-date-picker>
              </v-menu>
            </v-col>

            <v-col>
              <v-menu v-model="menuEnd" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
                offset-y min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="eventEnd" label="请选择结束日期" :prepend-icon="icons.mdiCalendar" readonly
                    v-bind="attrs" v-on="on"></v-text-field>
                </template>
                <v-date-picker v-model="eventEnd" locale="en" :min="eventStart"
                  @input="menuEnd = false"></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-select v-model="eventType" :items="projectDashboard.eventTypes" label="请选择事件类型" color="white"
                item-text="name" item-value="rowid" :rules="rulesSelect"></v-select>
            </v-col>
            <v-col>
              <v-text-field v-model="eventContent" label="请输入事件内容" required :rules="rulesContent"></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn large color="secondary" class="white--text text-h6" @click="eventDialog = false">
            取消
          </v-btn>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-btn class="white--text text-h6" large color="primary" :loading="loading" @click="onSubmit">
            确定
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div class="h-full d-flex w-full my-container">
      <div class="left d-flex flex-column" style="flex: 10">
        <div class="my-card" style="flex: 400">
          <Target />
        </div>
        <div class="my-card mt-3" style="flex: 263">
          <DangerPie />
        </div>
        <div class="my-card mt-3" style="flex: 263">
          <DangerLine />
        </div>
      </div>
      <div class="middle d-flex flex-column" style="flex:27">
        <div class="my-card" style="flex: 130">
          <Today />
        </div>
        <div class="my-card mt-3" style="flex: 818">
          <BgMap></BgMap>
        </div>
      </div>
      <div class="right d-flex flex-column" style="flex:10">
        <div class="my-card" style="flex: 247"><Alarm/></div>
        <div class="my-card mt-3" style="flex: 330"><Scene/></div>
        <div class="my-card mt-3" style="flex: 365"><Camera/></div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  mdiClose,
  mdiChevronLeft,
  mdiChevronRight,
  mdiChevronUp,
  mdiChevronDown,
  mdiPencil,
  mdiCalendar,
} from "@mdi/js";
import { mapGetters } from "vuex";
import countTo from "vue-count-to";
import { getShortMonth } from "@/utils/utils";
import BgMap from "@/components/BgMap/BgMap";
import Today from "../../components/Today/Today.vue";
import Target from "../../components/Target/Target.vue";
import DangerPie from "../../components/DangerPie/DangerPie.vue";
import DangerLine from "../../components/DangerLine/DangerLine.vue";
import Alarm from "../../components/Alarm/Alarm.vue";
import Scene from "../../components/Scene/Scene.vue";
import Camera from "../../components/Camera/Camera.vue";
export default {
  components: { countTo, BgMap, Today, Target, DangerPie, DangerLine, Alarm, Scene, Camera },
  data() {
    return {
      icons: {
        mdiClose,
        mdiChevronLeft,
        mdiChevronRight,
        mdiChevronUp,
        mdiChevronDown,
        mdiPencil,
        mdiCalendar,
      },
      focus: "",
      month: "",
      year: "",
      startDay: "",
      startMonth: "",
      endDay: "",
      endMonth: "",
      progress: 0,
      currentStart: null,
      currentEnd: null,
      // events
      isEdit: false,
      eventDialog: false,
      eventDialogTitle: "",
      menuStart: false,
      menuEnd: false,
      loading: false,
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      events: [],
      eventType: "",
      eventStart: "",
      eventEnd: "",
      eventContent: "",
      rulesSelect: [(v) => !!v || "事件类型必填"],
      rulesContent: [(v) => !!v || "事件内容必填"],
    };
  },
  computed: {
    ...mapGetters([
      "projectDashboard",
      "projectStart",
      "projectEnd",
      "projectRoles",
    ]),
    isAdmin() {
      if (this.projectRoles.find((e) => e == "admin")) {
        return true;
      } else {
        return false;
      }
    },
  },
  watch: {
    projectDashboard: {
      handler: function (dashboard) {
        console.log("watch projectDashboard:", dashboard);
        if (this.currentStart && this.currentEnd) {
          let param = {
            start: this.currentStart,
            end: this.currentEnd,
          };
          this.onChange(param);
        }
      },
      deep: true,
      immediate: true,
    },
    projectStart: {
      handler: function (startDate) {
        this.parseProgress();
      },
      immediate: true,
    },
    projectEnd: {
      handler: function (endDate) {
        this.parseProgress();
      },
      immediate: true,
    },
  },
  created() {
    console.log("dashboard created");
  },
  methods: {
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    onChange({ start, end }) {
      console.log(start, end, this.$refs.calendar.title);
      this.currentStart = start;
      this.currentEnd = end;
      this.year = start.year;
      this.month = getShortMonth(start.month).toUpperCase();

      const events = [];
      const min = new Date(`${start.date}T00:00:00`);
      const max = new Date(`${end.date}T23:59:59`);
      const days = (max.getTime() - min.getTime()) / 86400000;

      for (let i in this.projectDashboard.events) {
        let item = this.projectDashboard.events[i];
        let eventStart = new Date(`${item.start}T12:00:00`);
        let eventEnd = new Date(`${item.end}T12:00:00`);
        if (eventEnd < min || eventStart > max) {
          continue;
        }
        if (eventStart < min && min < eventEnd) {
          eventStart = min;
        } else if (eventStart < max && max < eventEnd) {
          eventEnd = max;
        }
        let color = this.projectDashboard.eventTypes.find(
          (e) => e.rowid == item.type
        );
        let obj = {
          rowid: item.rowid,
          name: item.content,
          start: eventStart,
          startString: item.start,
          endString: item.end,
          end: eventEnd,
          type: item.type,
          color: color ? color.colorCode : "black",
          timed: false,
        };
        console.log(i, obj.name, obj.start, obj.end, obj.timed);
        events.push(obj);
      }
      this.events = events;
    },
    getEventColor(event) {
      // console.log(event);
      return event.color;
    },
    parseProgress() {
      if (!this.projectStart || !this.projectEnd) {
        return;
      }
      let start = new Date(this.projectStart);
      let end = new Date(this.projectEnd);
      let now = new Date();
      this.startDay = start.getDate();
      this.startMonth = getShortMonth(start.getMonth() + 1).toUpperCase();
      this.endDay = end.getDate();
      this.endMonth = getShortMonth(end.getMonth() + 1).toUpperCase();

      //progress
      if (now <= start) {
        this.progress = 100;
      } else if (now >= end) {
        this.progress = 0;
      } else {
        let total = (end.getTime() - start.getTime()) / 86400000;
        let left = (end.getTime() - now.getTime()) / 86400000;
        this.progress = Math.floor((left * 100) / total);
      }
    },
    onClickEvent({ nativeEvent, event }) {
      if (!this.isAdmin) return;
      console.log("onClickEvent", event);
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        requestAnimationFrame(() =>
          requestAnimationFrame(() => (this.selectedOpen = true))
        );
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        requestAnimationFrame(() => requestAnimationFrame(() => open()));
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },
    onClickDate({ date }) {
      if (!this.isAdmin) return;
      console.log("onClickDate", date);
      this.isEdit = false;
      this.eventDialogTitle = "添加事件";
      this.eventStart = date;
      this.eventEnd = "";
      this.eventType = "";
      this.eventContent = "";
      this.eventDialog = true;
    },
    onClickMore({ date }) {
      console.log("onClickMore", date);
    },
    onEditEvent() {
      this.isEdit = true;
      this.eventDialogTitle = "编辑事件";
      this.eventStart = this.selectedEvent.startString;
      this.eventEnd = this.selectedEvent.endString;
      this.eventType = this.selectedEvent.type;
      this.eventContent = this.selectedEvent.name;
      this.eventDialog = true;
    },
    onDeleteEvent() {
      let that = this;
      if (this.selectedEvent && this.selectedEvent.rowid) {
        this.$messageBox({
          title: "删除事件",
          content: "是否确认删除事件<" + this.selectedEvent.name + ">?",
        })
          .then(async (res) => {
            let event = {
              rowid: that.selectedEvent.rowid,
            };
            await this.$store.dispatch("project/deleteEvent", {
              event,
            });
          })
          .catch((err) => { });
      }
    },
    async onSubmit() {
      if (
        !this.eventContent ||
        !this.eventType ||
        !this.eventStart ||
        !this.eventEnd
      ) {
        return;
      }

      this.loading = true;
      let event = {
        type: this.eventType,
        start: this.eventStart,
        end: this.eventEnd,
        content: this.eventContent,
      };
      if (this.isEdit) {
        event.rowid = this.selectedEvent.rowid;
        this.$store.dispatch("project/editEvent", { event });
      } else {
        this.$store.dispatch("project/addEvent", { event });
      }

      this.loading = false;
      console.log("new", this.projectDashboard);
      this.eventDialog = false;
    },
  },
};
</script>

<style lang="scss"></style>

<style lang="scss" scoped>
.my-card {
  box-shadow: 8px 8px 8px 0px rgba(0, 0, 0, 0.25);
  background-color: #EEEEEE;
}

.my-container {
  padding: 20px 15px;

  .left {}

  .middle {
    margin-left: 15px;
  }

  .right {
    margin-left: 15px;
  }
}


.dashboard-title {
  text-align: end;
  padding-bottom: 10px;
  // padding-left: 100px;
  border-bottom: $yumi-border-width solid $yumi-border-color;
}

.info-title {
  font-size: 130px;
  // letter-spacing: 10px;
  line-height: 1;
}

.info-subtitle {
  font-size: 25px;
  letter-spacing: 2px;
  color: #afb8c1;
}

// right
.dashboard-right {
  .progress-box {
    height: 20%;

    .dashboard-progress-subtitle {
      position: relative;
      bottom: -30px;

      .dashboard-progress-subtitle-text {
        border-bottom: 1px solid black;
      }
    }
  }

  .calendar-box {
    height: 80%;

    .calendar-toolbar {
      .calendar-btn {
        background-color: var(--v-primary-base);
      }

      .calendar-month-small {
        color: var(--v-secondary-base);
        font-family: "Arial-Black";
        font-size: 48px;
        width: 150px;
        transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        /* Internet Explorer 9*/
        -moz-transform: rotate(90deg);
        /* Firefox */
        -webkit-transform: rotate(90deg);
        /* Safari 和 Chrome */
        -o-transform: rotate(90deg);
        /* Opera */
        // letter-spacing: 6px;
      }
    }

    .calendar-main {
      position: relative;
      background-color: #f2f2f2;

      .calendar-now {
        position: absolute;
        right: 20px;
        bottom: 10px;

        .calendar-month-big,
        .calendar-year-big {
          color: #dce0e5;
          font-family: "Arial-Black";
          font-size: 170px;
          line-height: 1;
        }
      }

      .dashboard-calendar {
        background-color: transparent !important;
      }
    }
  }
}

// .theme--light.v-sheet {
//   background-color: transparent !important;
// }

#sheet-calendar {
  background-color: transparent !important;
}

.v-calendar ::v-deep {
  .v-calendar-weekly__head-weekday {
    font-size: 15px;
    z-index: 1;

    &.v-outside {
      background-color: transparent !important;
    }
  }

  .v-calendar-weekly__day {
    &.v-outside {
      background-color: transparent !important;

      .v-calendar-weekly__day-label {
        display: none;
      }
    }

    .v-calendar-weekly__day-label {
      .v-btn {
        font-size: 20px;
      }
    }

    .v-event {
      // width: 100% !important;
      padding-left: 4px;
    }
  }

  .v-event {
    // font-family: 'alibaba';
  }
}

#card-event {
  background-color: white !important;
}

//phone
@media (max-device-width: 600px) {
  .dashboard-title {
    text-align: start;
    padding-bottom: 10px;
    // padding-left: 100px;
    border-bottom: $yumi-border-width solid $yumi-border-color;
  }

  .info-title {
    font-size: 30px;
    // letter-spacing: 10px;
    line-height: 1;
  }

  .info-subtitle {
    font-size: 14px;
    letter-spacing: 2px;
    color: #afb8c1;
  }

  // right
  .dashboard-right {
    .progress-box {
      height: auto;

      .dashboard-progress-subtitle {
        position: relative;
        bottom: -12px;
      }
    }

    .calendar-box {
      height: 80%;

      .calendar-toolbar {
        margin-top: 15px;

        .calendar-btn {
          background-color: var(--v-primary-base);
        }

        .calendar-month-small {
          color: var(--v-secondary-base);
          font-family: "Arial-Black";
          font-size: 20px;
          width: 100%;
          text-align: center;
          transform: rotate(0deg);
          -ms-transform: rotate(0deg);
          /* Internet Explorer 9*/
          -moz-transform: rotate(90deg);
          /* Firefox */
          -webkit-transform: rotate(0deg);
          /* Safari 和 Chrome */
          -o-transform: rotate(0deg);
          /* Opera */
          // letter-spacing: 6px;
        }
      }

      .calendar-main {
        position: relative;
        background-color: #f2f2f2;

        .calendar-now {
          position: absolute;
          right: 20px;
          bottom: 10px;

          .calendar-month-big,
          .calendar-year-big {
            color: #dce0e5;
            font-family: "Arial-Black";
            font-size: 100px;
            line-height: 1;
          }
        }

        .dashboard-calendar {
          background-color: transparent !important;
        }
      }
    }
  }

  .v-calendar ::v-deep {
    .v-calendar-weekly__head-weekday {
      font-size: 12px;
      z-index: 1;

      &.v-outside {
        background-color: transparent !important;
      }
    }

    .v-calendar-weekly__day {
      &.v-outside {
        background-color: transparent !important;

        .v-calendar-weekly__day-label {
          display: none;
        }
      }

      .v-calendar-weekly__day-label {
        .v-btn {
          height: 30px;
          width: 30px;
          font-size: 15px;
        }
      }

      .v-event {
        font-size: 8px;
        line-height: 16px;
        // width: 100% !important;
        padding-left: 0px;
      }
    }
  }
}
</style>
