<template>
    <div class="h-full d-flex flex-column">
        <div class="d-flex px-2 pt-0 pb-1 justify-space-between align-center">
            <YTag title="安全体征" />
            <div class="text-pangmen total mb-1">{{ average }}</div>
            <div class="d-flex flex-column text-caption mr-2">
                <div class="d-flex justify-space-between  align-center">
                    <div class="pr-4">同比</div>
                    <div :class="tongbi > 0 ? 'show-green' : 'show-red'">{{ tongbi }}%</div>
                </div>
                <div class="d-flex justify-space-between  align-center">
                    <div class="pr-4">环比</div>
                    <div :class="huanbi > 0 ? 'show-green' : 'show-red'">{{ huanbi }}%</div>
                </div>
            </div>
        </div>
        <v-carousel v-model="activeIndex" :continuous="false" :cycle="false" :show-arrows="false"
            hide-delimiter-background height="17.8rem" class="carousel-content" style="flex:1">
            <v-carousel-item v-for="(slide, slideIndex) in slides" :key="slideIndex">
                <v-sheet color="transparent" height="100%" tile class="pb-10 px-6">
                    <v-row class="fill-height" no-gutters>
                        <v-col v-for="item in slide" :key="item.rowid" cols="4" align="center" class="py-1">
                            <v-img :src="require('@/assets/images/yma/shield.png')" height="3.2rem" width="3.45rem">
                                <div class="text-h5 white--text font-weight-bold"
                                    style="margin-top: 0.5rem; margin-right: 1px;">{{ item.value }}</div>
                            </v-img>
                            <div class="text-caption">{{ item.dockName }}</div>
                        </v-col>
                    </v-row>
                </v-sheet>
            </v-carousel-item>
        </v-carousel>
    </div>
</template>

<script>
import { mdiCircle } from "@mdi/js";
import YTag from '../YTag/YTag'
import { getTargets } from '@/api/security.js'
export default {
    components: { YTag },
    data() {
        return {
            icons: {
                mdiCircle,
            },
            activeIndex: 0,
            average: 97.6,
            tongbi: 1,
            huanbi: -1,
            slides: [
                'First',
                'Second'
            ],
            items: {
                'First': [
                    11, 22, 33, 44, 55, 6, 77, 88, 99
                ],
                'Second': [11, 22]
            }
        }
    },
    mounted() {
        this.refresh()
    },
    methods: {
        async refresh() {
            let result = await getTargets()
            // console.log('results', result)
            if (!result.success) {
                return
            }
            let total = 0
            let len = 0
            let slides = []
            let slideIndex = -1
            for (let i = 0; i < result.data.length; i++) {
                total += parseInt(result.data[i].value)
                len += 1
                if (i % 9 == 0) {
                    // change slideIndex
                    slideIndex += 1
                    slides[slideIndex] = []
                }
                slides[slideIndex].push(result.data[i])
            }
            if (len) {
                this.average = (total / len).toFixed(1)
            }
            this.slides = slides
        }
    }
}
</script>

<style lang="scss" scoped>
.total {
    font-size: 2.5rem;
    color: #3E8CDD;
}

.show-red {
    color: rgb(255, 69, 13)
}

.show-green {
    color: rgb(33, 135, 73)
}

.carousel-content {
    display: flex;
}

.carousel-content ::v-deep {
    .v-window__container {
        width: 100%;
    }

    .v-carousel__controls__item.v-btn {
        color: #C4C4C4 !important;
    }

    .v-carousel__controls__item.v-btn--active {
        color: #58A1F6 !important;
    }

    /* Change hover delimiter color */
    .v-carousel__controls__item.v-btn:hover {
        color: #58A1F6 !important;
    }

    .v-btn--icon.v-size--small {
        height: auto;
        width: auto;
    }
}
</style>