<template>
    <div class="h-full d-flex flex-column">
        <v-dialog v-model="showDialog" transition="dialog-top-transition" max-width="1337px"
            content-class="dialog-internel2" class="dialog-wrapper">
            <template v-slot:default="dialog">
                <div class="dialog-container2 d-flex">
                    <div class="dialog-container-left">
                        <v-list color="#E0E6E7">
                            <v-list-item-group v-model="currentTask" mandatory :ripple="false">
                                <v-list-item v-for="(task, i) in tasks" :key="i" :ripple="true" class="my-list-item">
                                    <template v-slot:default="{ active }">
                                        <v-list-item-content>
                                            <v-img
                                                :src="active ? require('@/assets/images/yma/item-active.png') : require('@/assets/images/yma/item.png')"
                                                width="195" height="73">
                                                <div class="text-h6 ml-2 mt-1"
                                                    :style="{ color: active ? 'white' : 'black' }">{{ task.text }}</div>
                                                <div class="text-subtitle-2 ml-2 mt-2"
                                                    :style="{ color: active ? 'white' : 'black' }">{{ task.text }}</div>
                                            </v-img>
                                        </v-list-item-content>
                                    </template>
                                </v-list-item>
                            </v-list-item-group>
                        </v-list>
                    </div>
                    <div class="dialog-container-right d-flex flex-column" style="flex:1">
                        <v-toolbar color="#E0E6E7" dense flat class="dialog-toolbar pt-2" style="flex:0">
                            <v-spacer></v-spacer>
                            <v-item-group class="d-flex" mandatory :value='currentTab' @change="onTabChange">
                                <div class="tab mx-2" v-for="item in tabs" :key="item.name">
                                    <v-item v-slot="{ active, toggle }" :value="item.name">
                                        <YTag :title="`${item.name}`" :type="active ? 'primary' : 'secondary'"
                                            class="white--text text-siyuan" paddingRight="50px"
                                            @click="onTabChange(item)"></YTag>
                                    </v-item>
                                </div>
                            </v-item-group>
                            <v-spacer></v-spacer>
                            <div class="d-flex align-center h-full">
                                <v-btn icon @click="dialog.value = false" color="accent" small>
                                    <v-icon :size="50"> {{ icons.mdiClose }}</v-icon>
                                </v-btn>
                            </div>
                        </v-toolbar>
                        <div style="flex:1">
                            <v-simple-table v-if="currentTab == '处置列表'" fixed-header height="auto" dense class="my-table">
                                <template v-slot:default>
                                    <thead>
                                        <tr>
                                            <th class="text-center text-subtitle-2 font-weight-bold"
                                                style="color: var(--v-primary-base) !important;"
                                                v-for="header in headers" :key="header">
                                                {{ header }}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="item in desserts" :key="item.index">
                                            <td class="text-center text-subtitle-2 font-weight-bold">{{ item.index }}
                                            </td>
                                            <td class="text-center text-subtitle-2 font-weight-regular">{{ item.name }}
                                            </td>
                                            <td class="text-center text-subtitle-2 font-weight-bold">{{ item.user }}
                                            </td>
                                            <td class="text-center text-subtitle-2 font-weight-bold">{{ item.time }}
                                            </td>
                                            <td class="text-center text-subtitle-2 font-weight-bold">{{ item.action }}
                                            </td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                            <FlowMap v-if="currentTab == '处置流程'" />
                        </div>
                    </div>
                </div>
            </template>
        </v-dialog>
        <div class="d-flex px-2 pt-2 pb-1 justify-space-between align-center">
            <YTag title="场景工厂" />
        </div>
        <v-carousel v-model="activeIndex" :continuous="false" :cycle="false" :show-arrows="false"
            hide-delimiter-background height="172" class="carousel-content w-full" style="flex:1;">
            <v-carousel-item v-for="(slide, i) in slides" :key="i">
                <v-sheet color="transparent" height="100%" tile class="pt-1 px-6 d-flex align-center">
                    <v-row class="fill-height mb-3" dense>
                        <v-col v-for="item in slide" :key="item.name" cols="4" align="center" class="">
                            <div class="scene-card pt-1" :class="item.stat == '处置中' ? 'scene-card-active' : ''"
                                @click="onClick(item)">
                                <v-img :src="item.icon" :height="item.iconHeight" :width="item.iconWidth">
                                </v-img>
                                <div class="text-subtitle-2">{{ item.name }}</div>
                                <div class="text-caption">{{ item.stat }}</div>
                            </div>
                        </v-col>
                    </v-row>
                </v-sheet>
            </v-carousel-item>
        </v-carousel>
    </div>
</template>

<script>
import { mdiClose } from "@mdi/js";
import YTag from '../YTag/YTag'
import FlowMap from "./FlowMap.vue";
const iconw = '2rem'
const iconh = '1.8rem'
export default {
    components: { YTag, FlowMap },
    data() {
        return {
            icons: {
                mdiClose,
            },
            showDialog: false,
            activeIndex: 0,
            currentTask: null,   //左侧当前任务
            slides: [
                [{
                    name: '台风',
                    stat: '正常',
                    iconWidth: iconw,
                    iconHeight: iconh,
                    icon: require('@/assets/images/yma/taifeng.png')
                }, {
                    name: '潮位',
                    stat: '处置中',
                    iconWidth: iconw,
                    iconHeight: iconh,
                    icon: require('@/assets/images/yma/chaowei.png')
                }, {
                    name: '前沿水深',
                    stat: '正常',
                    iconWidth: iconw,
                    iconHeight: iconh,
                    icon: require('@/assets/images/yma/qianyan.png')
                }, {
                    name: '客流监测',
                    stat: '正常',
                    iconWidth: iconw,
                    iconHeight: iconh,
                    icon: require('@/assets/images/yma/keliu.png')
                }, {
                    name: '航班监测',
                    stat: '正常',
                    iconWidth: iconw,
                    iconHeight: iconh,
                    icon: require('@/assets/images/yma/hangban.png')
                }, {
                    name: '船舶碰撞',
                    stat: '正常',
                    iconWidth: iconw,
                    iconHeight: iconh,
                    icon: require('@/assets/images/yma/pengzhuang.png')
                }],
                [{
                    name: '冰冻',
                    stat: '正常',
                    iconWidth: iconw,
                    iconHeight: iconh,
                    icon: require('@/assets/images/yma/bingdong.png')
                }, {
                    name: '游客摔倒',
                    stat: '正常',
                    iconWidth: iconw,
                    iconHeight: iconh,
                    icon: require('@/assets/images/yma/shuaidao.png')
                }, {
                    name: '游客异常',
                    stat: '正常',
                    iconWidth: iconw,
                    iconHeight: iconh,
                    icon: require('@/assets/images/yma/yichang.png')
                }]
            ],
            items: [
                11, 22, 33, 44, 55, 6, 77, 88, 99
            ],
            tasks: [
                {
                    icon: 'mdi-inbox',
                    text: 'Inbox',
                },
                {
                    icon: 'mdi-star',
                    text: 'Star',
                },
                {
                    icon: 'mdi-send',
                    text: 'Send',
                },
                {
                    icon: 'mdi-email-open',
                    text: 'Drafts',
                },
                {
                    icon: 'mdi-send',
                    text: 'Send',
                },
                {
                    icon: 'mdi-email-open',
                    text: 'Drafts',
                },
                {
                    icon: 'mdi-send',
                    text: 'Send',
                },
                {
                    icon: 'mdi-email-open',
                    text: 'Drafts',
                },
            ],
            currentTab: '处置列表',
            tabs: [
                { name: '处置列表', count: 0 },
                { name: '处置流程', count: 0 },
            ],
            headers: ['序号', '节点', '处置人', '处置开始时间/结束时间', '处置操作'],
            desserts: [
                {
                    index: 1,
                    name: '信息录入 人脸识别系统',
                    user: '系统',
                    time: '2024-03-11 06:00 / 06:00 ',
                    action: '已采集',
                },
                {
                    index: 2,
                    name: '信息录入 人脸识别系统',
                    user: '系统',
                    time: '2024-03-11 06:00 / 06:00 ',
                    action: '已采集',
                },
                {
                    index: 3,
                    name: '信息录入 人脸识别系统',
                    user: '系统',
                    time: '2024-03-11 06:00 / 06:00 ',
                    action: '已采集',
                }]
        }
    },
    mounted() {

    },
    methods: {
        onClick(item) {
            if (item.stat != '处置中') return
            this.showDialog = true
            this.currentTab = '处置列表'
        },
        onTabChange(item) {
            console.log('tab', item.name)
            this.currentTab = item.name
        }
    }
}
</script>

<style lang="scss" scoped></style>

<style lang="scss" scoped>
.scene-card {
    border: #145BBA solid 1px;
    box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.scene-card-active {
    cursor: pointer;
    border: #FF704E solid 1px;

    .text-caption {
        background-color: #FF704E;
        color: white;
    }
}

/* 设置滚动条宽度 */
* {
    scrollbar-width: thin;
    scrollbar-color: rgba(60, 60, 60, 0.2) rgba(60, 60, 60, 0.0);
}

.dialog-internel2 {
    border-radius: 0px;
    box-shadow: 8px 8px 8px 0px rgba(154, 149, 149, 0.25) !important;
    max-height: 96% !important;
}

.dialog-container2 ::v-deep {
    background-color: #E0E6E7;
    border: 1px solid rgb(27, 114, 185);
    height: 83vh;

    .dialog-container-left {
        border-right: #145BBA solid 1px;
        overflow-y: auto;
    }

    .v-list-item {
        padding: 0px;
        margin: 5px;

        .v-list-item__content {
            padding: 0px;
        }

        .v-list-item__content:active {
            background-color: transparent;
        }
    }

    .tab {
        cursor: pointer;
    }

}

.my-list-item ::v-deep {
    .v-list-item--active::before {
        opacity: 0 !important;
    }

    .v-list-item--active {
        background-color: transparent;
    }

    .v-list-item--highlighted {
        opacity: 0 !important;
        background-color: transparent;
    }

    .v-list-item__mask {
        opacity: 0 !important;
        background-color: transparent;
    }

    .v-list-item--active:focus .v-list-item__ripple,
    .v-list-item--highlighted:focus .v-list-item__ripple {
        display: none;
        opacity: 0 !important;
        background-color: transparent;
    }
}

.carousel-content {
    display: flex;
    align-items: center;
}

.carousel-content ::v-deep {
    .v-window__container {
        width: 100%;
    }

    .v-carousel__controls {
        height: 24px;
    }

    .v-carousel__controls__item.v-btn {
        color: #C4C4C4 !important;
    }

    .v-carousel__controls__item.v-btn--active {
        color: #58A1F6 !important;
    }

    /* Change hover delimiter color */
    .v-carousel__controls__item.v-btn:hover {
        color: #58A1F6 !important;
    }

    .v-btn--icon.v-size--small {
        height: auto;
        width: auto;
    }
}

.text-center {
    text-align: center !important;
}

.my-table ::v-deep {
    margin: 10px;
    margin-top: 20px;
    border: var(--v-primary-base) solid 1px;
    border-radius: 0;

    .v-data-table__wrapper {
        background-color: #E0E6E7;

        th {
            background-color: #E0E6E7 !important;
        }
    }
}
</style>