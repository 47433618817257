<template>
    <div id="map" ref="mapContainer" :style="mapStyle"></div>
</template>

<script>
import { fontSize } from '../../utils/utils'
import { getEffeciencyYear } from '../../api/resource'
export default {
    props: {
        dockCode: {
            type: String,
            default: "",
        },
        type: {
            type: String,
            default: "year",
        }
    },
    data() {
        return {
            diamond_height: '16',
            diamond_offset_neg: '-8',
            diamond_offset: '8',
            chart: null,
            yearAxis: [],
            monthAxis: [],
            yearData: [],
            monthData: [],
            compData: [],
            fullData: [],
            chartOption: {
                "title": {
                    "text": "万元/米",
                    "left": 12,
                    "top": 12,
                    "textStyle": {
                        fontSize: 14,
                        "color": "#000"
                    }
                },
                tooltip: {
                    trigger: 'axis',
                    formatter: '{b0}年<br/>坪效:{c0}万元/米'
                },
                legend: {
                    top: '3%',
                    right: '3%',
                    icon: 'rect',
                    itemWidth: 14,
                    data: [
                        {
                            name: '已完成',
                            itemStyle: {
                                color: new this.$echarts.graphic.LinearGradient(
                                    0, 0, 0, 1,
                                    [
                                        { offset: 0, color: 'rgb(0, 144, 251)' },
                                        { offset: 1, color: 'rgb(189, 217, 241)' }
                                    ]
                                )
                            }
                        }
                    ]
                },
                grid: {
                    bottom: '20%',
                    top: '30%',
                    left: '15%'
                },
                xAxis: {
                    type: 'category',
                    "axisTick": {
                        "show": false
                    },
                    "splitLine": {
                        "show": false
                    },
                    "axisLine": {
                        "symbol": [
                            "none"
                        ],
                        "symbolSize": [
                            10,
                            17
                        ],
                        "lineStyle": {
                            "color": "#000",
                            "width": 0
                        }
                    },
                    offset: 10,
                    boundaryGap: false,
                    axisLabel: {
                        fontSize: 12
                    },
                    data: ['23/11', '23/12', '24/01', '24/02', '24/03', '24/04', '24/05', '24/06']
                },
                yAxis: [{
                    type: 'value',
                    name: '',
                    position: 'left',
                    offset: 20,
                    axisLabel: {
                        fontSize: 12,
                    }
                }],
                series: [
                    {
                        // 0
                        type: 'bar',
                        stack: "left",
                        showSymbol: false,
                        yAxisIndex: 0,
                        smooth: true,
                        name: '已完成',
                        data: [5, 2, 3, 6, 8, 10, 9, 8],
                        itemStyle: {
                            color: {
                                type: "linear",
                                x: 0,
                                y: 0,
                                x2: 0,
                                y2: 1,
                                colorStops: [
                                    {
                                        offset: 0,
                                        color: 'rgba(0, 144, 251)'
                                    },
                                    {
                                        offset: 1,
                                        color: 'rgb(189, 217, 241)'
                                    }
                                ]
                            }
                        },
                    },
                    {
                        // 1
                        name: "bg",
                        type: "bar",
                        stack: "left",
                        itemStyle: {
                            "color": "#00000020"
                        },
                        data: [3, 3, 3, 3, 3, 3, 3, 3]
                    },
                    {
                        // 2
                        "name": "bottom",
                        "tooltip": {
                            "show": false
                        },
                        "type": "pictorialBar",
                        "itemStyle": {
                            "color": "rgba(0, 144, 251)",
                            "borderColor": "#000",
                            "borderWidth": 0.1,
                            "label": {
                                "show": false,
                                "position": "top",
                                "textStyle": {
                                    "color": "#FFFFFF",
                                    "fontSize": 14
                                },
                                "offset": [
                                    0,
                                    -2
                                ]
                            }
                        },
                        "symbol": "diamond",
                        "symbolSize": [
                            "90%",
                            '24'
                        ],
                        "symbolOffset": [
                            0,
                            '12'
                        ],
                        "symbolPosition": "start",
                        "data": [5, 2, 3, 6, 8, 10, 9, 8],
                        "z": 100
                    },
                    {
                        // 3
                        "name": "value",
                        "tooltip": {
                            "show": false
                        },
                        "type": "pictorialBar",
                        "itemStyle": {
                            "color": "rgba(0, 144, 251)",
                            "borderColor": "#000",
                            "borderWidth": 0.1,
                            "label": {
                                "show": false,
                                "position": "top",
                                "textStyle": {
                                    "color": "#FFFFFF",
                                    "fontSize": 14
                                },
                                "offset": [
                                    0,
                                    -2
                                ]
                            }
                        },
                        "symbol": "diamond",
                        "symbolSize": [
                            "90%",
                            this.diamond_height
                        ],
                        "symbolOffset": [
                            0,
                            this.diamond_offset_neg
                        ],
                        "symbolPosition": "end",
                        data: [5, 2, 3, 6, 8, 10, 9, 8],
                        "z": 100
                    },
                    {
                        // 4
                        "name": "top",
                        "tooltip": {
                            "show": false
                        },
                        "type": "pictorialBar",
                        "itemStyle": {
                            "color": "rgba(0, 144, 251)",
                            "borderColor": "#000",
                            "borderWidth": 0.1,
                            "label": {
                                "show": false,
                                "position": "top",
                                "textStyle": {
                                    "color": "#FFFFFF",
                                    "fontSize": 14
                                },
                                "offset": [
                                    0,
                                    -2
                                ]
                            }
                        },
                        "symbol": "diamond",
                        "symbolSize": [
                            "90%",
                            this.diamond_height
                        ],
                        "symbolOffset": [
                            0,
                            this.diamond_offset_neg
                        ],
                        "symbolPosition": "end",
                        data: Array.from({ length: 8 }, (_, index) => 10),
                        "z": 100
                    }
                ]
            }
        }
    },
    watch: {
        dockCode: {
            handler: function (newvalue, oldvalue) {
                console.log('dockCode handler', newvalue, oldvalue)
                if (oldvalue && newvalue) {
                    this.refresh();
                }
            },
            immediate: true,
        },
        type: {
            handler: function (value) {
                this.redraw();
            },
            immediate: true,
        }
    },
    computed: {
        mapStyle() {
            let obj = {
                width: "100%",
                height: '100%'
            };
            return obj;
        },
    },
    mounted() {
        var that = this;
        this.$nextTick(() => {
            console.log("Frequency: init echarts, dockCode:", this.dockCode);
            this.chart = this.$echarts.init(this.$refs.mapContainer);
            window.onresize = function () {
                console.log("do resize");
                that.chart.resize();
            };
            this.refresh()
            // this.chart.on("click", this.onClick);
        });
    },
    methods: {
        async refresh() {
            let result = await getEffeciencyYear(this.dockCode)
            console.log('result:', result)
            // 今年
            if (result.success) {
                let nowYear = new Date().getFullYear()
                let yearAxis = []
                let yearData = []
                let fullData = []   //完整最大
                let compData = []   //补偿条
                let maxYear = 0
                for (let i in result.data) {
                    let row = result.data[i]
                    let year = row['year'].substring(0, 4)
                    maxYear = Math.max(maxYear, parseInt(year))
                    yearAxis.push(year + '年')
                    yearData.push(parseFloat(row['income']))
                }
                // 补充到今年
                for (let i = maxYear; i < nowYear; i++) {
                    yearAxis.push((i + 1) + '年')
                    yearData.push(0)
                }
                let maxValue = Math.max(...yearData) * 1.2
                console.log('max value:', maxValue)
                for (let i in yearData) {
                    fullData.push(maxValue)
                    compData.push(maxValue - yearData[i])
                }
                this.yearData = yearData
                this.yearAxis = yearAxis
                this.fullData = fullData
                this.compData = compData
                console.log('d:', yearData, yearAxis, fullData, compData)
            } else {
                this.yearAxis = []
                this.yearData = []
            }
            // 按月
            // result = await getFrequencyMonth(this.dockCode)
            // if (result.success) {
            //     this.monthAxis = result.data.key.map(item => {
            //         let arr = item.split('/')
            //         if (arr.length) {
            //             return arr[1]
            //         } else {
            //             return item
            //         }
            //     })
            //     this.monthData = result.data.value
            // } else {
            //     this.monthAxis = []
            //     this.monthData = []
            // }
            this.redraw()
        },
        redraw() {
            console.log('redraw type:', this.type, this.dockCode)
            if (this.type == 'year') {
                this.chartOption.xAxis.data = this.yearAxis
                let series = [this.yearData, this.compData, this.yearData, this.yearData, this.fullData]
                for (let i in series) {
                    this.chartOption.series[i].data = series[i]
                }
                this.chartOption.series[2].symbolSize[1] = this.diamond_height
                this.chartOption.series[2].symbolOffset[1] = this.diamond_offset
                this.chartOption.series[3].symbolSize[1] = this.diamond_height
                this.chartOption.series[3].symbolOffset[1] = this.diamond_offset_neg
                this.chartOption.series[4].symbolSize[1] = this.diamond_height
                this.chartOption.series[4].symbolOffset[1] = this.diamond_offset_neg
                this.chartOption.tooltip.formatter = '{b0}<br/>已完成:{c0}'
            } else {
                // this.chartOption.xAxis.data = this.monthAxis
                // this.chartOption.series[0].data = this.monthData
                // this.chartOption.tooltip.formatter = '{b0}日<br/>靠泊次数:{c0}'
            }
            if (this.chart) {
                this.chart.setOption(this.chartOption, false);
            }
        }
    }
}
</script>

<style lang="scss" scoped></style>