<template>
    <div :class="['ytag', `color-${type}`]" :style="{ paddingRight: paddingRight, height: height, lineHeight: height }"
        @click="emitClick">{{ title }}
    </div>
</template>

<script>
import { VDivider } from 'vuetify/lib'
export default {
    name: 'YTag',
    props: {
        title: {
            type: String,
            default: ''
        },
        type: {
            type: String,
            default: 'primary'
        },
        paddingRight: {
            type: String,
            default: '1.875rem'
        },
        height: {
            type: String,
            default: '2.25rem'
        }
    },
    methods: {
        emitClick() {
            console.log("emit")
            this.$emit('click')
        }
    }
}
</script>

<style lang="scss" scoped>
.ytag {
    position: relative;
    // height: 36px;
    // line-height: 36px;
    width: auto;
    font-size: 1.125rem;
    color: #fff;
    font-weight: 700;
    padding-left: 7px;
    padding-right: 1.875rem;
    // background: linear-gradient(-45deg, transparent 15px, #58a 0);
}

// .ytag::after {
//     content: "";
//     position: absolute;
//     bottom: 0;
//     right: 0;
//     width: 20px;
//     height: 20px;
//     background: transparent;
//     transform: translate(50%, 50%) rotate(45deg);
// }


.color-primary {
    background: linear-gradient(-45deg, transparent 13px, $yma_tagcolor_primary_right 0, $yma_tagcolor_primary_left);
}

.color-secondary {
    background: linear-gradient(-45deg, transparent 13px, $yma_tagcolor_secondary_right 0, $yma_tagcolor_secondary_left);
}

.color-youchuan {
    background: linear-gradient(-45deg, transparent 13px, $yma_tagcolor_youchuan 0, $yma_tagcolor_youchuan);
    text-shadow: 2px 2px 2px #888888;
}

.color-youting {
    background: linear-gradient(-45deg, transparent 13px, $yma_tagcolor_youting 0, $yma_tagcolor_youting);
    text-shadow: 2px 2px 2px #888888;
}

.color-gongwu {
    background: linear-gradient(-45deg, transparent 13px, $yma_tagcolor_gongwu 0, $yma_tagcolor_gongwu);
    text-shadow: 2px 2px 2px #888888;
}

.color-lundu {
    background: linear-gradient(-45deg, transparent 13px, $yma_tagcolor_lundu 0, $yma_tagcolor_lundu);
    text-shadow: 2px 2px 2px #888888;
}
</style>