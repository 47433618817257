<template>
    <div class="w-full h-full d-flex" style="line-height: 1.2;position: relative;">
        <div class="d-flex flex-end today-link">
            <a href="http://app.huangpurivercrusies.com:9001/app/0c033ce9-7808-476c-945b-d0337488c226/65ae2e762b525e0d8a27cba7/65ae31902b525e0d8a27cbff?flag=1712819154099"
                target="_blank" class="text-decoration-none">
                <v-icon x-small dense class="mr-1">
                    $vuetify.icons.ymaToday
                </v-icon>前往全局态势
            </a>
        </div>
        <div style="flex: 355" class="d-flex align-center">
            <div class="d-flex flex-column ml-6" style="flex: 48">
                <div class="block">今日</div>
                <div class="block">航班</div>
            </div>
            <div class="d-flex flex-column mx-5 mr-6" style="flex: 191">
                <div class="d-flex align-center justify-space-between">
                    <div class="title">
                        计划航班
                    </div>
                    <div class="ban ml-2">{{ jihua }}</div>
                </div>
                <div class="d-flex align-center justify-space-between">
                    <div class="title">
                        已执行航班
                    </div>
                    <div class="ban ml-2">{{ zhixing }}</div>
                </div>
            </div>
        </div>
        <v-divider vertical inset />
        <div style="flex: 719" class="d-flex align-center">
            <div class="d-flex flex-column ml-6" style="flex: 48">
                <div class="block">今日</div>
                <div class="block">客流</div>
            </div>
            <div class="d-flex flex-column mx-5 mr-6" style="flex: 191">
                <div class="d-flex align-center justify-space-between">
                    <div class="title">
                        计划运力
                    </div>
                    <div class="ren ml-2">{{ yunli }}</div>
                </div>
                <div class="d-flex align-center justify-space-between">
                    <div class="title">
                        已接待客流量
                    </div>
                    <div class="ren ml-2">{{ jiedai }}</div>
                </div>
            </div>
            <div class="d-flex flex-column mx-5 mr-6" style="flex: 191">
                <div class="d-flex align-center justify-space-between">
                    <div class="title">
                        今日待售
                    </div>
                    <div class="ren ml-2">{{ daishou }}</div>
                </div>
                <div class="d-flex align-center justify-space-between">
                    <div class="title">
                        已售待检
                    </div>
                    <div class="ren ml-2">{{ daijian }}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { getToday } from '@/api/resource.js'
export default {
    data() {
        return {
            jihua: '',
            zhixing: '',
            yunli: '',
            jiedai: '',
            daishou: '',
            daijian: ''
        }
    },
    mounted() {
        this.refresh()
    },
    methods: {
        async refresh() {
            let result = await getToday()
            if (!result.success) {
                return
            }
            let jihua = result.data.length
            let zhixing = 0
            let yunli = 0
            let jiedai = 0
            let daishou = 0
            let daijian = 0
            for (let i in result.data) {
                let item = result.data[i]
                if (parseInt(item.startFlag) == 1) {
                    zhixing += 1
                }
                yunli += parseInt(item.seatNum)
                jiedai += parseInt(item.CheckCount)
                daishou += parseInt(item.wait)
                daijian += parseInt(item.unchecked)
            }
            this.jihua = jihua
            this.zhixing = zhixing
            this.yunli = yunli
            this.jiedai = jiedai
            this.daishou = daishou
            this.daijian = daijian
        }
    }
}
</script>

<style lang="scss" scoped>
.today-link {
    position: absolute;
    top: 0.43rem;
    right: 1rem;
    font-size: 0.625rem;
    color: rgb(98, 167, 246);
    z-index: 100;
}

.block {
    font-size: 1.5rem;
    font-weight: bold;
}

.title {
    color: #448EF7;
    font-size: 1.125rem;
    font-weight: bold;
    position: relative;
    padding-left: 26px;
    /* 左侧留出一定空间供方块显示 */
}

.title:before {
    content: '';
    /* 伪元素需要content属性，即使为空 */
    position: absolute;
    left: 0;
    /* 定位到左侧 */
    top: 50%;
    /* 方块垂直居中 */
    transform: translateY(-50%);
    /* 修正垂直位置 */
    width: 16px;
    /* 方块宽度 */
    height: 16px;
    /* 方块高度 */
    background-color: #448EF7;
    /* 方块颜色 */
}

.ban,
.ren {
    color: #444444;
    font-size: 1.5rem;
    font-family: 'pangmen';
    position: relative;
    margin-bottom: 0.25rem;
}

.ban::after {
    content: '班';
    color: #888888;
    font-size: 1.125rem;
}

.ren::after {
    content: '人';
    color: #888888;
    font-size: 1.125rem;
}
</style>