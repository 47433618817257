<template>
    <div class="h-full d-flex flex-column">
        <v-dialog v-model="showDialog" transition="dialog-top-transition" max-width="1337px"
            content-class="dialog-internel3" @click:outside="onDialogClose">
            <template v-slot:default="dialog">
                <div class="dialog-container3">
                    <v-btn icon @click="onDialogClose" color="accent" small class="dialog-container3-btn">
                        <v-icon :size="50"> {{ icons.mdiClose }}</v-icon>
                    </v-btn>
                    <video id="dialog-video" class="vjs-default-skin vjs-4-3" autoplay muted preload="auto" width="100%"
                        height="70%"></video>
                </div>
            </template>
        </v-dialog>
        <div class="d-flex px-2 pt-2 pb-1 justify-space-between align-center">
            <YTag title="码头实况" />
            <v-select v-model="currentDock" class="select-input flex-grow-0" :items="dockList" outlined dense
                single-line hide-details style="width: 13rem;" @change="onDockChange"></v-select>
        </div>
        <v-carousel v-model="activeIndex" :continuous="false" :cycle="false" :show-arrows="false"
            hide-delimiter-background height="100%" class="carousel-content w-full" style="flex:1;"
            :hide-delimiters="slides.length == 1" @change="onPageChange">
            <v-carousel-item v-for="(slide, i) in slides" :key="i">
                <v-sheet color="transparent" height="90%" tile class="pt-1 px-6 d-flex align-center">
                    <v-row class="fill-height mb-3" dense>
                        <v-col v-for="(item, itemIndex) in slide" :key="item.name" cols="6" align="center" class="">
                            <div class="camera-card" :class="(itemIndex % 2) == 1 ? 'camera-card-col2' : ''"
                                @click="onDialogOpen(item)">
                                <!-- <v-img :src="item.icon" :height="item.iconHeight" :width="item.iconWidth">
                                </v-img> -->
                                <video :id="item.id" class="vjs-default-skin vjs-4-3 camera-card-video" autoplay muted
                                    preload="auto" width="90%" height="90%" :poster="posterUrl">
                                </video>
                                <div class="text-caption">{{ item.position }}</div>
                            </div>
                        </v-col>
                    </v-row>
                </v-sheet>
            </v-carousel-item>
        </v-carousel>
    </div>
</template>

<script>
import { mdiClose } from "@mdi/js";
import YTag from '../YTag/YTag'
import { getCameras } from '@/api/security'
import videojs from 'video.js'
import 'video.js/dist/video-js.css'
import dynamicPoster from '@/assets/images/yma/camera2.png'; // 导入动态图片
const iconw = '2rem'
const iconh = '1.8rem'
export default {
    components: { YTag },
    data() {
        return {
            icons: {
                mdiClose,
            },
            showDialog: false,
            dialogVideo: null,
            activeIndex: 0,
            currentCamera: null,   //左侧当前任务
            currentDock: '',
            currentPageMax: 0,   //当前翻过的最大页索引，用于初始化videojs
            posterUrl: dynamicPoster,
            dockList: [],
            cameraList: [],
            slides: [
            ],
            items: [
            ]
        }
    },
    mounted() {
        console.log('Camera mounted')
        this.refresh()
    },
    beforeDestroy() {
        console.log('Camera unmounted now3')
        for (let i in this.cameraList) {
            let camera = this.cameraList[i]
            if (camera.player) {
                console.log('dispose:', camera.id)
                camera.player.dispose()
                camera.player = null
            }
        }
        if (this.dialogVideo) {
            this.dialogVideo.dispose()
            this.dialogVideo = null
        }
    },
    methods: {
        async refresh() {
            let result = await getCameras()
            console.log('Camera: refresh result:', result)
            if (result.success) {
                console.log('getCameras:', result.data)
                this.cameraList = result.data.map(item => {
                    let code = item.code.split('_')[1]
                    let obj = {
                        id: 'video-' + item.autono,
                        dock_name: item.dock_name,
                        dock_code: item.dock_code,
                        name: item.name,
                        position: item.position,
                        code: code,
                        player: null,
                        address_lbr: "http://192.168.111.138:8012/yma_lbr/" + code + "/hls.m3u8",
                        address_hbr: "http://192.168.111.138:8012/yma_hbr/" + code + "/hls.m3u8",
                    }
                    // real addr
                    obj.address_lbr = "https://192.168.111.95/camera/yma_lbr/" + code + "/hls.m3u8"
                    obj.address_hbr = "https://192.168.111.95/camera/yma_hbr/" + code + "/hls.m3u8"
                    // for test
                    obj.address_lbr = "https://demo.m3u8play.com/m3u8/out/demo.m3u8"
                    obj.address_hbr = "https://bitdash-a.akamaihd.net/content/sintel/hls/playlist.m3u8"
                    return obj
                })
                for (let i = 0; i < result.data.length; i++) {
                    let dock = result.data[i]
                    if (i == 0) {
                        this.currentDock = dock.dock_name
                    }
                    if (!this.dockList.includes(dock.dock_name)) {
                        this.dockList.push(dock.dock_name)
                    }
                }
                this.filter()
            }
        },
        filter() {
            var that = this
            // 填充slides
            let cameras = this.cameraList.filter(e => e.dock_name == this.currentDock)
            let slides = []
            let slide = []
            for (let i in cameras) {
                let camera = cameras[i]
                slide.push(camera)
                if ((parseInt(i) + 1) % 4 == 0) {
                    slides.push(slide)
                    slide = []
                }
            }
            if (slide.length) {
                slides.push(slide)
            }
            this.slides = slides
            setTimeout(() => {
                that.fillPlayers()
            }, 500);
            // this.$nextTick(() => {

            // })
        },
        fillPlayers(index = 0) {
            let cameras = this.cameraList.filter(e => e.dock_name == this.currentDock)
            for (let i = 0; i < cameras.length; i++) {
                let camera = cameras[i]
                if (i < index * 4) continue
                else if (i == index * 4 + 4) break
                // console.log('current dock', this.currentDock, camera.id)
                if (camera.player) {
                    camera.player.dispose()
                    camera.player = null
                }
                camera.player = videojs(camera.id, {
                    loadingSpinner: false,
                    fluid: true
                },)
                camera.player.src({ type: 'application/x-mpegURL', src: camera.address_lbr });
                camera.player.play()
                // if (parseInt(i) < 4) {
                //     camera.player.play()
                // } else {
                //     camera.player.pause()
                // }
                camera.player.on('error', function () {
                    // 在错误发生时，延迟一定时间后重新加载
                    console.log("error and retry", camera.id)
                    camera.player.load();
                });
            }
        },
        onDialogOpen(item) {
            var that = this
            this.showDialog = true
            if (item) {
                setTimeout(() => {
                    if (!that.dialogVideo) {
                        that.dialogVideo = videojs('dialog-video', {
                            fluid: true
                        });
                    }
                    that.dialogVideo.src({ type: 'application/x-mpegURL', src: item.address_hbr });
                    that.dialogVideo.ready(function () {
                        this.play();
                    });
                }, 500);
            }
        },
        onDialogClose() {
            // 关闭弹框时停止视频播放
            console.log('onDialogClose')
            if (this.dialogVideo) {
                this.dialogVideo.pause()
                this.dialogVideo.currentTime(0)
            }
            this.showDialog = false
        },
        onDockChange() {
            console.log('current dock', this.currentDock)
            this.filter()
            this.currentPageMax = 0
        },
        onPageChange(value) {
            var that = this
            console.log('current index|max:', value, this.currentPageMax)
            if (value > this.currentPageMax) {
                this.currentPageMax = value
                setTimeout(() => {
                    that.fillPlayers(value)
                }, 1000);
            }
        }
    }
}
</script>

<style lang="scss" scoped></style>

<style lang="scss" scoped>
.camera-card {
    position: relative;
    // width: 8rem;
    // height: 5rem;
    cursor: pointer;
    border: #219A98 solid 3px;
    height: 6rem;

    .text-caption {
        position: absolute;
        bottom: 0;
        height: 1rem;
        width: 100%;
        background-color: #219A98;
        opacity: 0.8;
        color: white;
        z-index: 100;
    }
}

.camera-card-col2 {
    cursor: pointer;
    border: #008DE0 solid 3px;

    .text-caption {
        background-color: #008DE0;
        color: white;
    }
}

video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

/* 设置滚动条宽度 */
* {
    scrollbar-width: thin;
    scrollbar-color: rgba(60, 60, 60, 0.2) rgba(60, 60, 60, 0.0);
}

.dialog-internel3 {
    border-radius: 0px;
    box-shadow: 8px 8px 8px 0px rgba(154, 149, 149, 0.25) !important;
}

.dialog-container3 ::v-deep {
    position: relative;
    background-color: #E0E6E7;
    border: 1px solid rgb(27, 114, 185);
    height: 83vh;
    background-color: black;

    .dialog-container3-btn{
        position: absolute;
        top: 1rem;
        right: 1rem;
        z-index: 1000;
    }

    .dialog-container-left {
        border-right: #145BBA solid 1px;
        overflow-y: auto;
    }

    .v-list-item {
        padding: 0px;
        margin: 5px;

        .v-list-item__content {
            padding: 0px;
        }

        .v-list-item__content:active {
            background-color: transparent;
        }
    }

    .tab {
        cursor: pointer;
    }

}

.my-list-item ::v-deep {
    .v-list-item--active::before {
        opacity: 0 !important;
    }

    .v-list-item--active {
        background-color: transparent;
    }

    .v-list-item--highlighted {
        opacity: 0 !important;
        background-color: transparent;
    }

    .v-list-item__mask {
        opacity: 0 !important;
        background-color: transparent;
    }

    .v-list-item--active:focus .v-list-item__ripple,
    .v-list-item--highlighted:focus .v-list-item__ripple {
        display: none;
        opacity: 0 !important;
        background-color: transparent;
    }
}

.carousel-content {
    display: flex;
    align-items: center;
}

.carousel-content ::v-deep {
    .v-window__container {
        width: 100%;
    }

    .v-carousel__controls {
        height: 24px;
    }

    .v-carousel__controls__item.v-btn {
        color: #C4C4C4 !important;
    }

    .v-carousel__controls__item.v-btn--active {
        color: #58A1F6 !important;
    }

    /* Change hover delimiter color */
    .v-carousel__controls__item.v-btn:hover {
        color: #58A1F6 !important;
    }

    .v-btn--icon.v-size--small {
        height: auto;
        width: auto;
    }
}

.text-center {
    text-align: center !important;
}

.my-table ::v-deep {
    margin: 10px;
    margin-top: 20px;
    border: var(--v-primary-base) solid 1px;
    border-radius: 0;

    .v-data-table__wrapper {
        background-color: #E0E6E7;

        th {
            background-color: #E0E6E7 !important;
        }
    }
}

.select-input ::v-deep {
    font-size: 1rem !important;

    .v-input__slot {
        padding-left: 10px !important;
        padding-right: 0px !important;
    }
}
</style>