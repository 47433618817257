import { mdapi } from "./mdapi";

const URLPREFIX = 'http://app.huangpurivercrusies.com:9001/api'
const APPKEY = '07675c3c9dc17e9d'
const SIGN = 'NDQwZTE0NTVlMjliNGQ2OTYyZWFmMzlmNzJmNGNkYzIwYmFlYWU1MWEwNDk2YWIxOTBhZjQ5NjkxNGQ2NGZmOA=='
const DOCK_WSID = '63821984ea0e201ac31fcf19'
const TODAY_WSID = '6384b87cea0e201ac31fd03e'
const INCOME_YEAR_WSID = '64294e620830355e66c7f7a1'
const INCOME_MONTH_WSID = '64294c8f0830355e66c7f761'
const CH_URL = 'http://app.huangpurivercrusies.com:9003/ymabigscrn/api/common/chquery_toml'

const getDocks = () => {
    return new Promise(async (resolve, reject) => {
        let result = {
            success: false,
            message: '',
            data: ''
        }
        try {
            let res = null
            let rowid = ''

            let filters = [{
                "controlId": "state",
                "dataType": mdapi.DataType.NUMBER,
                "spliceType": mdapi.SpliceType.AND,
                "filterType": mdapi.FilterType.EQ,
                "value": 1
            }]
            let pageSize = 1
            let pageIndex = 1
            let sortId = 'ctime'
            let isAsc = false
            res = await mdapi.getFilterRowsAll(URLPREFIX, APPKEY, SIGN, DOCK_WSID, filters).catch(e => {
                console.log("getDocks error:", e)
                result.message = e.toString()
            })

            if (!res) {
                resolve(result)
                return
            }

            // console.log("res is:", res)
            if (!res.success || !res.data) {
                // md error
                result.message = '网络错误'
                resolve(result)
                return
            }
            result.success = true
            result.data = res.data.rows
            resolve(result)
        } catch (error) {
            result.message = error.toString()
            resolve(result)
        }
    })
}

// 今日航班信息
const getToday = () => {
    return new Promise(async (resolve, reject) => {
        let result = {
            success: false,
            message: '',
            data: ''
        }
        try {
            let res = null
            let rowid = ''

            let filters = []
            let pageSize = 1
            let pageIndex = 1
            let sortId = 'ctime'
            let isAsc = false
            res = await mdapi.getFilterRowsAll(URLPREFIX, APPKEY, SIGN, TODAY_WSID, filters).catch(e => {
                console.log("getToday error:", e)
                result.message = e.toString()
            })

            if (!res) {
                resolve(result)
                return
            }

            if (!res.success || !res.data) {
                // md error
                result.message = '网络错误'
                resolve(result)
                return
            }
            result.success = true
            result.data = res.data.rows
            resolve(result)
        } catch (error) {
            result.message = error.toString()
            resolve(result)
        }
    })
}

// 年度客流和航班数
const getYearInfo = (dockCode) => {
    return new Promise(async (resolve, reject) => {
        let result = {
            success: false,
            message: '',
            data: ''
        }
        try {
            let res = null
            let rawString = `call_name = "ticket_history"
            query = '''
                SELECT
                    toInt32(sum(CheckCount)) as total_visitor,
                    toInt32(count()) as totol_sched
                FROM ticket_history
                WHERE toDate(ScheduleTime) >= addDays(today(), -366)
                AND DockCode == '{dock_code}'
                FORMAT JSON
            '''
            
            [ch_fields]
            delta=0
            dock_code="${dockCode}"
            `
            res = await fetch(CH_URL, {
                method: "POST",
                headers: {
                    'Authentication': 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJleHAiOjE3MzU1NzQ0MDAsImlhdCI6MTcwNDAwODE5MiwiYXBwdHlwZSI6InltYWJpZ3Njcm4iLCJmdW5jcyI6ImNocXVlcnkifQ.nYp0GIMeUpDzZpWikj8hvxEdTtgjpRTk5Y9af-6LPrY'
                },
                body: new Blob([rawString], { type: 'text/plain' })
            }).catch(e => {
                console.log("getDocks error:", e)
                result.message = e.toString()
            })

            if (!res) {
                resolve(result)
                return
            }

            result.data = await res.json()

            console.log('get getYearInfo res:', result.data)
            result.success = true
            resolve(result)
        } catch (error) {
            result.message = error.toString()
            resolve(result)
        }
    })
}

// 按年坪效
const getEffeciencyYear = (dockCode) => {
    return new Promise(async (resolve, reject) => {
        let result = {
            success: false,
            message: '',
            data: ''
        }
        try {
            let res = null
            let rowid = ''
            let deviceId = ""
            let macAddr = ""

            let filters = [{
                "controlId": "dock_code",
                "dataType": mdapi.DataType.TEXT,
                "spliceType": mdapi.DataType.AND,
                "filterType": mdapi.FilterType.EQ,
                "value": dockCode
            }]
            let pageSize = 1
            let pageIndex = 1
            let sortId = 'year'
            let isAsc = true
            res = await mdapi.getFilterRowsAll(URLPREFIX, APPKEY, SIGN, INCOME_YEAR_WSID, filters, '', sortId, isAsc).catch(e => {
                console.log("getDocks error:", e)
                result.message = e.toString()
            })

            if (!res) {
                resolve(result)
                return
            }

            // console.log("res is:", res)
            if (!res.success || !res.data) {
                // md error
                result.message = '网络错误'
                resolve(result)
                return
            }
            result.success = true
            result.data = res.data.rows
            resolve(result)
        } catch (error) {
            result.message = error.toString()
            resolve(result)
        }
    })
}

// 利用频次-按年
const getFrequencyYear = (dockCode) => {
    return new Promise(async (resolve, reject) => {
        let result = {
            success: false,
            message: '',
            data: ''
        }
        try {
            let res = null
            let rawString = `call_name = "ticket_history"
            query = '''SELECT
                concat(toString(toYear(today())), '/', toString(month)) as key,
                count as value
            FROM(
                SELECT
                    toInt32(count() * 2) as count,
                    toMonth(ScheduleTime) as month
                FROM ticket_history
                WHERE toYear(ScheduleTime) == toYear(today()-{delta}) AND DockCode == '{dock_code}'
                GROUP BY month
                ORDER by month
                    WITH FILL
                    FROM 1 to 13
            )
            FORMAT JSONColumns'''
            
            [ch_fields]
            delta=3
            dock_code="${dockCode}"
            `
            res = await fetch(CH_URL, {
                method: "POST",
                headers: {
                    'Authentication': 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJleHAiOjE3MzU1NzQ0MDAsImlhdCI6MTcwNDAwODE5MiwiYXBwdHlwZSI6InltYWJpZ3Njcm4iLCJmdW5jcyI6ImNocXVlcnkifQ.nYp0GIMeUpDzZpWikj8hvxEdTtgjpRTk5Y9af-6LPrY'
                },
                body: new Blob([rawString], { type: 'text/plain' })
            }).catch(e => {
                console.log("getDocks error:", e)
                result.message = e.toString()
            })

            if (!res) {
                resolve(result)
                return
            }

            result.data = await res.json()

            console.log('get getFrequencyYear res:', result.data)
            result.success = true
            resolve(result)
        } catch (error) {
            result.message = error.toString()
            resolve(result)
        }
    })
}

// 利用频次-按月
const getFrequencyMonth = (dockCode) => {
    return new Promise(async (resolve, reject) => {
        let result = {
            success: false,
            message: '',
            data: ''
        }
        try {
            let res = null
            let rawString = `call_name = "ticket_history"
            query = '''
            SELECT
                formatDateTime(mday, '%m/%d') as key,
                count as value
            FROM(
                SELECT
                    toInt32(count() * 2) as count,
                    toDate(ScheduleTime) as mday
                FROM ticket_history
                WHERE toYYYYMM(ScheduleTime) == toYYYYMM(today()-{delta}) AND DockCode == '{dock_code}'
                GROUP BY mday
                ORDER by mday
                    WITH FILL
                    FROM toStartOfMonth(today()-{delta}) to addDays(toLastDayOfMonth(today()-{delta}),1)
            )
            FORMAT JSONColumns
            '''
            
            [ch_fields]
            delta=0
            dock_code="${dockCode}"
            `
            res = await fetch(CH_URL, {
                method: "POST",
                headers: {
                    'Authentication': 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJleHAiOjE3MzU1NzQ0MDAsImlhdCI6MTcwNDAwODE5MiwiYXBwdHlwZSI6InltYWJpZ3Njcm4iLCJmdW5jcyI6ImNocXVlcnkifQ.nYp0GIMeUpDzZpWikj8hvxEdTtgjpRTk5Y9af-6LPrY'
                },
                body: new Blob([rawString], { type: 'text/plain' })
            }).catch(e => {
                console.log("getDocks error:", e)
                result.message = e.toString()
            })

            if (!res) {
                resolve(result)
                return
            }

            result.data = await res.json()

            console.log('get getFrequencyMonth res:', result.data)
            result.success = true
            resolve(result)
        } catch (error) {
            result.message = error.toString()
            resolve(result)
        }
    })
}

export { getDocks, getToday, getYearInfo, getEffeciencyYear, getFrequencyYear, getFrequencyMonth };