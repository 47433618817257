<template>
    <div class="h-full d-flex flex-column">
        <div class="d-flex px-2 pt-2 pb-1 align-center">
            <YTag title="重大隐患管理" />
        </div>
        <div style="flex:1">
            <div id="map" ref="mapContainer" :style="mapStyle"></div>
        </div>
    </div>
</template>

<script>
import YTag from '../YTag/YTag'
import { fontSize } from '../../utils/utils'
export default {
    components: { YTag },
    props: {

    },
    data() {
        return {
            chart: null,
            chartOption: {
                color: ['#DE3B6D', '#3580E0'],
                legend: {
                    top: '3%',
                    right: '3%',
                    icon: 'rect',
                    itemWidth: 14,
                    data: [
                        {
                            name: '重大安全隐患数',
                            itemStyle: {
                                color: new this.$echarts.graphic.LinearGradient(
                                    0, 0, 0, 1,
                                    [
                                        { offset: 0, color: 'rgba(223, 64, 113)' },
                                        { offset: 1, color: 'rgba(235, 201, 211)' }
                                    ]
                                )
                            }
                        }, {
                            name: '整改率',
                            itemStyle: {
                                color: new this.$echarts.graphic.LinearGradient(
                                    0, 0, 0, 1,
                                    [
                                        { offset: 0, color: 'rgba(0, 144, 251)' },
                                        { offset: 1, color: 'rgba(189, 217, 241)' }
                                    ]
                                )
                            }
                        }
                    ]
                },
                tooltip: {
                    trigger: 'axis',
                },
                grid: {
                    bottom: '20%',
                    top: '30%'
                },
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    axisLabel: {
                        fontSize: fontSize(10)
                    },
                    data: ['23/11', '23/12', '24/01', '24/02', '24/03', '24/04', '24/05', '24/06']
                },
                yAxis: [{
                    type: 'value',
                    name: '',
                    position: 'left',
                    alignTicks: true,
                    axisLabel: {
                        fontSize: fontSize(12),
                    }
                }, {
                    type: 'value',
                    name: '',
                    position: 'right',
                    alignTicks: true,
                    axisLabel: {
                        align: 'right',
                        margin: fontSize(30),
                        fontSize: fontSize(12),
                        formatter: '{value}%'
                    }
                }],
                series: [
                    {
                        type: 'line',
                        yAxisIndex: 0,
                        smooth: true,
                        name: '重大安全隐患数',
                        showSymbol: false,
                        data: [5, 2, 3, 6, 8, 10, 9, 8],
                        areaStyle: {// 设置渐变色
                            color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                                offset: 0,
                                color: 'rgba(223, 64, 113, 0.8)'
                            }, {
                                offset: 1,
                                color: 'rgba(235, 201, 211, 0.8)'
                            }])
                        }
                    },
                    {
                        type: 'line',
                        yAxisIndex: 1,
                        smooth: true,
                        name: '整改率',
                        showSymbol: false,
                        data: [10, 20, 50, 60, 80, 70, 40, 35],
                        areaStyle: {
                            color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                                offset: 0,
                                color: 'rgba(0, 144, 251, 0.8)'
                            }, {
                                offset: 1,
                                color: 'rgba(189, 217, 241, 0.8)'
                            }])
                        }
                    }
                ]
            }
        }
    },
    computed: {
        mapStyle() {
            let obj = {
                width: "100%",
                height: '100%'
            };
            return obj;
        },
    },
    mounted() {
        var that = this;
        this.$nextTick(() => {
            console.log("DangerBar: init echarts");
            this.chart = this.$echarts.init(this.$refs.mapContainer);
            window.onresize = function () {
                console.log("do resize");
                that.chart.resize();
            };
            this.chart.setOption(this.chartOption, false);
            // this.chart.on("click", this.onClick);
        });
    }
}
</script>

<style lang="scss" scoped></style>