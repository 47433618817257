<template>
    <div id="map" ref="mapContainer" :style="mapStyle"></div>
</template>

<script>
export default {
    props: {
        quwei: {
            type: String,
            default: "",
        },
        pingxiao: {
            type: String,
            default: "",
        },
        pinci: {
            type: String,
            default: "",
        },
        kaobo: {
            type: String,
            default: "",
        },
        zonghe: {
            type: String,
            default: "",
        }
    },
    data() {
        return {
            chart: null,
            chartOption: {
                title: {
                    show: false,
                    text: 'Basic Radar Chart'
                },
                tooltip: {
                    trigger: 'axis'
                },
                radar: {
                    // shape: 'circle',
                    indicator: [
                        { name: '区位', max: 10 },
                        { name: '利用频次', max: 10 },
                        { name: '靠泊能力', max: 10 },
                        { name: '综合通过能力', max: 10 },
                        { name: '坪效', max: 10 },
                    ],
                    axisName: {
                        color: '#000',
                        fontWeight: 700,
                        fontFamily: 'siyuan',
                        fontSize: 18
                    },
                    splitArea: {
                        areaStyle: {
                            color: ['#C8E2E8', '#B4D9EA', '#C8E2E8', '#B4D9EA', '#C8E2E8']
                        }
                    },
                    splitLine: {
                        lineStyle: {
                            color: '#A1CBEB',
                            type: 'dashed'
                        }
                    },
                    axisLine: {
                        lineStyle: {
                            color: '#9FCAEB'
                        }
                    }
                },
                series: [
                    {
                        name: 'Budget vs spending',
                        type: 'radar',
                        tooltip: {
                            trigger: 'item'
                        },
                        data: [
                            {
                                value: [],
                                name: '五维图评分结果',
                                areaStyle: {
                                    color: '#74A3C2'
                                },
                                itemStyle: {
                                    color: '#58C0F9'
                                }
                            }
                        ]
                    }
                ]
            }
        }
    },
    watch: {
        quwei: {
            handler: function (value) {
                this.refresh();
            },
            immediate: true,
        },
        pinci: {
            handler: function (value) {
                this.refresh();
            },
            immediate: true,
        },
        kaobo: {
            handler: function (value) {
                this.refresh();
            },
            immediate: true,
        },
        zonghe: {
            handler: function (value) {
                this.refresh();
            },
            immediate: true,
        },
        pingxiao: {
            handler: function (value) {
                this.refresh();
            },
            immediate: true,
        }
    },
    computed: {
        mapStyle() {
            let obj = {
                width: "100%",
                height: '100%'
            };
            return obj;
        },
    },
    mounted() {
        var that = this;
        this.$nextTick(() => {
            console.log("FiveDimension: init echarts");
            this.chart = this.$echarts.init(this.$refs.mapContainer);
            window.onresize = function () {
                console.log("do resize");
                that.chart.resize();
            };
            this.refresh()
            // this.chart.on("click", this.onClick);
        });
    },
    methods: {
        refresh() {
            let values = []
            values.push(parseFloat(this.quwei))
            values.push(parseFloat(this.pinci))
            values.push(parseFloat(this.kaobo))
            values.push(parseFloat(this.zonghe))
            values.push(parseFloat(this.pingxiao))
            // console.log('five dimension values:', values)
            this.chartOption.series[0].data[0].value = values
            if (this.chart) {
                this.chart.setOption(this.chartOption, false);
            }
        }
    }
}
</script>

<style lang="scss" scoped></style>